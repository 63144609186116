import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white px-5 py-3 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-3">
          <h2 className="text-2xl font-bold pb-5">Page Not Found</h2>
          <div>
            <p className="pb-6">The page you are requesting doesn't exist</p>
            <div>
              <button
                onClick={() => navigate('/welcome')}
                className="underline"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
