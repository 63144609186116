import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/fetcher';
import Spinner from '../common/Spinner';
import { UserT } from '../../utils/types';
import { signOut } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import updateUserInExtension from '../../utils/updateUserInExtension';

type Props = {
  user: UserT | undefined;
  postLogout: () => void;
  markActivated: () => void;
};

export default function VerifyUser({ user, markActivated, postLogout }: Props) {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    if (user.isVerified) {
      navigate('/welcome');
      return;
    }
  }, [user, navigate]);

  const [input, setInput] = useState<string>('');

  const GENERIC_ERR_MSG =
    "we couldn't verify your activation code, please try again later";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value;
    setInput(value);
  };

  const resetError = () => {
    setError('');
  };

  const handleVerification = async (event: any) => {
    if (loading) {
      return;
    }
    event.preventDefault();
    if (!user || !input) {
      return;
    }
    try {
      setLoading(true);
      const response: {
        status: 'success' | 'failed';
      } = await post({
        path: 'api/activate',
        payload: {
          code: input,
        },
      });

      if (response && response.status === 'success') {
        markActivated();
        setLoading(false);
        navigate('/welcome');
      } else if (response.status === 'failed') {
        setError(
          "it looks like you didn't entered the correct activation code, please try again"
        );
      } else {
        setError(GENERIC_ERR_MSG);
      }
      setLoading(false);
    } catch (error) {
      setError(GENERIC_ERR_MSG);
      console.error('Failed to make verification call', error);
      setLoading(false);
    }
  };

  const handleOtherAccountClick = async () => {
    signOut(auth);
    try {
      updateUserInExtension({}, 'user-logout');
    } catch (error: any) {
      // just log in console if couldn't communicate to extension
      // atleast logout from website
      console.log(error);
    }
    await post({
      path: 'api/logout',
    });
    postLogout();
  };

  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white px-5 py-3 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-3">
          <h2 className="text-2xl font-bold">Enter activation code</h2>
          <div>
            <form className="mt-6" action="#" method="POST">
              <div className="text-sm">
                <p className="pb-4">
                  SquareX is invite-only for now. Your activation code should be
                  in your invite email.
                </p>
                <p>
                  Please fill&nbsp;
                  <a
                    className="underline"
                    href="https://forms.gle/3zzsCUGifgXyjefR7"
                  >
                    this form
                  </a>
                  &nbsp;if you need an invite.
                </p>
              </div>
              {error !== undefined && (
                <div className="text-sm text-red-500 pt-5">{error}</div>
              )}
              <div>
                <input
                  type="text"
                  placeholder="Activation code"
                  value={input}
                  onChange={handleInputChange}
                  onFocus={resetError}
                  autoFocus
                  required
                  className="w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </div>
              <button
                id="activate-btn"
                type="submit"
                className="w-full inline-block align-middle text-white font-semibold rounded px-4 py-3 mt-6 bg-purple-800 hover:bg-button"
                onClick={handleVerification}
                disabled={loading && true}
              >
                {!user || loading ? <Spinner size={6} /> : 'Activate'}
              </button>
              <div className="text-center mt-3 text-sm font-semibold text-gray-700">
                <button
                  className="underline"
                  onClick={handleOtherAccountClick}
                  type="button"
                >
                  Use different account?
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
