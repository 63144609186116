import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getBrowserField,
  getCleanBrowserName,
} from '../../utils/browserSupport';
import VideoModalButton from '../common/VideoModalButton';

export default function PostInstall() {
  const [currentBrowser, setCurrentBrowser] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentBrowser(getCleanBrowserName());
  }, []);

  return (
    <>
      <div className="w-full fixed pt-[200px]">
        <div className="text-[1.5rem] max-w-[1440px] mx-auto px-6 vp:px-0">
          <p className="text-4xl mb-10">Final Steps</p>
          <p className="text-xl mb-5">
            Great Progress! You're almost finished setting up the extension.
          </p>
          <p className="text-xl">
            Follow the instructions in the right side to complete setting up
            SquareX.
          </p>
        </div>
      </div>
      <div className={`fixed ${getBrowserField(currentBrowser, 'pin-right')}`}>
        <img src="/arrow.gif" className="max-h-[120px]" alt="arrow up" />
      </div>
      <div className="fixed top-[6rem] right-[2.25rem] border bg-white flex rounded shadow-lg p-5">
        <div className="w-full p-3">
          <h6 className="text-xl font-bold pb-5 flex align-middle">
            Keep SquareX Visible: Pin It!
          </h6>
          <p>
            <img
              src={getBrowserField(currentBrowser, 'pin-gif')}
              className="max-w-[356px]"
              alt="pin the extension"
            />
          </p>
          <div className="flex max-h-[50px] align-middle">
            <div className="w-full text-center">
              <VideoModalButton
                className="align-middle rounded text-sm px-4 py-3 mt-6 text-gray-700 bg-gray-500 hover:bg-gray-500/80 mr-4"
                vimeo="https://player.vimeo.com/video/877790622?autoplay=1"
                videoWidth={768}
              >
                🎬 Video Guide
              </VideoModalButton>
              <button
                type="button"
                onClick={() => navigate('/faq')}
                className="align-middle rounded text-sm px-4 py-3 mt-6 text-gray-700 bg-gray-500 hover:bg-gray-500/80 mr-4"
              >
                🙋 View FAQs
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
