export const faqs = [
  {
    title: 'General',
    qas: [
      {
        q: 'Which browsers can I use SquareX on?',
        a: [
          {
            type: 'text',
            content:
              process.env.REACT_APP_ENABLE_FIREFOX !== 'true'
                ? 'SquareX extension supports Google Chrome, Microsoft Edge, and Brave at the moment. However, the Web App can be used in all major desktop browsers including Firefox and Safari'
                : 'SquareX extension supports Google Chrome, Microsoft Edge, Firefox and Brave at the moment. However, the Web App can be used in all major desktop browsers including Safari',
          },
        ],
      },
      {
        q: 'Is there a dark mode for SquareX? ',
        a: [
          {
            type: 'text',
            content:
              'Yes. Click on the dark mode icon on the top right corner on the extension or webapp.',
          },
          {
            type: 'image',
            content: '/faq/general1.png',
          },
        ],
      },
      {
        q: 'What data do you collect?',
        a: [
          {
            type: 'text',
            content:
              'We only collect data that is necessary to provide you with our services. Please find detailed information on our <a href="https://sqrx.com/terms" style="text-decoration:underline" target="_blank">TOS</a> and <a href="https://sqrx.com/privacy" style="text-decoration:underline" target="_blank">Privacy policy</a>.',
          },
        ],
      },
      {
        q: 'Is my personal information stored or shared when using SquareX?',
        a: [
          {
            type: 'text',
            content:
              'We take user privacy seriously. Your online activities, browsing history, and data remain entirely private to you, and once the Disposable File Viewer, Disposable Browser, or Disposable Email is disposed, all the session history is completely deleted. ',
          },
        ],
      },
      {
        q: 'Do you sell my personal information to third parties?',
        a: [
          {
            type: 'text',
            content: 'No, we do not sell your personal information to anyone.',
          },
        ],
      },
      {
        q: 'Can I use SquareX anonymously?',
        a: [
          {
            type: 'text',
            content:
              'Absolutely. You can use our service anonymously. We do not require any personally identifying information to use our service apart from an email address.',
          },
        ],
      },
      {
        q: 'How can I submit a bug report explaining a problem I encountered with SquareX?',
        a: [
          {
            type: 'text',
            content:
              "Click on the profile icon on the SquareX extension, followed by the 'Report a Bug' tab.",
          },
          {
            type: 'text',
            content:
              'You can also submit a bug report by filling out <a href="/report-bug" target="_blank" style="text-decoration:underline">the form here.</a>',
          },
          { type: 'image', content: '/faq/general2.png' },
        ],
      },
      {
        q: 'How can I get rid of the grouping of tabs that you provide?',
        a: [
          {
            type: 'text',
            content:
              'Click on the gear icon next to the Disposable Browser header. Here, you can enable and disable the Tab Group settings',
          },
          { type: 'image', content: '/faq/general3.png' },
          { type: 'image', content: '/faq/general4.png' },
        ],
      },
      {
        q: 'How can I temporarily disable SquareX?',
        a: [
          {
            type: 'text',
            content:
              'Click on the profile icon and use the toggle provided to temporarily enable or disable SquareX.',
          },
          { type: 'image', content: '/faq/general5.png' },
        ],
      },
      {
        q: 'How can I delete my account?',
        a: [
          {
            type: 'text',
            content:
              'You can request for account deletion by visiting <a href="/delete-account-request" style="text-decoration:underline" target="_blank">this page</a>',
          },
        ],
      },
    ],
  },
  {
    title: 'Disposable Browser',
    qas: [
      {
        q: 'What are disposable browsers, and why would I need them?',
        a: [
          {
            type: 'text',
            content:
              'Disposable or Burner browsers are your personal temporary web browsers that aim to provide a layer of anonymity and security to you while browsing the internet. They allow you to access websites without leaving any trace of your browsing history, cookies, or personal information.',
          },
          {
            type: 'text',
            content:
              'These browsers are particularly useful when you need to conduct sensitive tasks, access restricted content, or protect your identity while browsing.',
          },
        ],
      },
      {
        q: 'Why do I see two search bars when I open a Disposable Browser?',
        a: [
          {
            type: 'text',
            content:
              'You see two search bars because the Disposable Browser uses remote isolation technology to create a secure and disposable window for you to browse with. Use the lower search bar to interact with the disposable environment and to browse safely & anonymously.',
          },
          {
            type: 'text',
            content:
              'You can also turn on the “Full Screen Mode” from the control panel present at the bottom right corner of the Disposable Browser to see only one search bar.',
          },
          { type: 'image', content: '/faq/db1.png' },
        ],
      },
      {
        q: 'Can I see Disposable Browser in full screen?',
        a: [
          {
            type: 'text',
            content:
              'Yes, you can switch to full-screen mode by opening the control panel on the bottom right, inside the Disposable Browser and clicking on full screen button as below. ',
          },
          { type: 'image', content: '/faq/db2.png' },
        ],
      },
      {
        q: 'Can I open multiple locations of Disposable Browsers together?',
        a: [
          {
            type: 'text',
            content:
              'Yes, you can use Disposable Browsers in multiple locations simultaneously. Simply select the location of your choice and start browsing.',
          },
        ],
      },
      {
        q: 'What happens when I close the Disposable Browser?',
        a: [
          {
            type: 'text',
            content:
              'There are two ways to close the Disposable Browser and what happens when you follow either is different:',
          },
          {
            type: 'text',
            content:
              '<strong>Option 1:</strong> You close the Disposable Browser by clicking on the small <strong>x</strong> sign next to the country name in the extension / web app. When you close the Disposable Browser this way, all your browsing history is deleted forever.',
          },
          {
            type: 'image',
            content: '/faq/db3.png',
          },
          {
            type: 'text',
            content:
              '<strong>Option 2:</strong> You close the Disposable Browser by closing the tab from the top. This does not dispose your session, and your session remains active on your extension. You can jump back into browsing from where you left when you click on the active session on the extension.',
          },
          {
            type: 'image',
            content: '/faq/db4.png',
          },
          {
            type: 'text',
            content:
              'However, if the session has timed out, the Disposable Browser session will get disposed automatically, deleting all the data, irreversibly.',
          },
        ],
      },
      {
        q: 'Does SquareX track what I do online when I browse on the Disposable Browsers? ',
        a: [
          {
            type: 'text',
            content:
              'No, SquareX does not track any of your browsing activity.',
          },
        ],
      },
      {
        q: "Why can't I copy-paste things from my normal browser to the Disposable Browser?",
        a: [
          {
            type: 'text',
            content:
              'You may have missed out on giving permission to the clipboard to copy-paste text. Follow the quick steps below to fix it:',
          },
          {
            type: 'text',
            content:
              '<strong>For Chrome/Brave/Edge/Chromium-based browser:</strong>',
          },
          {
            type: 'text',
            content:
              'Step 1: Open the Disposable Browser session in your chosen country ',
          },
          {
            type: 'text',
            content:
              'Step 2: Click on the lock button on the top-left side of the Disposable Browser ',
          },
          {
            type: 'text',
            content: 'Step 3: Go to Clipboard and allow copy-paste ',
          },
          {
            type: 'image',
            content: '/faq/db5.png',
          },
          {
            type: 'text',
            content: '<strong>For Firefox/Safari browser:</strong>',
          },
          {
            type: 'text',
            content:
              'Step 1: Open the Disposable Browser session in your chosen country ',
          },
          {
            type: 'text',
            content:
              'Step 2: Clipboard permissions are not allowed in Firefox / Safari by default. So, to use the copy/paste in/out of disposable browser, open the disposable browser controls from bottom right, and click on the "Remote Clipboard" button',
          },
          {
            type: 'image',
            content: '/faq/db6.png',
          },
          {
            type: 'text',
            content:
              'Step 3: Now to paste to disposable browser, simply paste in the text area visible. This will update the clipboard of disposable browser, now enabling you to paste the content inside the disposable browser.',
          },
          {
            type: 'text',
            content:
              'Step 4: To copy from disposable browser, simply copy inside the disposable browser, this will update the text in the text area visible. You can copy again from this text area to copy to your local machine.',
          },
          {
            type: 'image',
            content: '/faq/db7.png',
          },
        ],
      },
      {
        q: 'Why am I asked for clipboard permissions?',
        a: [
          {
            type: 'text',
            content:
              'Giving clipboard permissions allows you to copy the text from outside the disposable browser to inside seamlessly, and vice-versa.',
          },
        ],
      },
      {
        q: 'I did not close my Disposable Browser but just saw the message that all my browsing history has been deleted, why did this happen?',
        a: [
          {
            type: 'text',
            content:
              'Currently you are using the free version of SquareX and so each browsing session within the Disposable Browser is timed to 10 minutes. After 10 minutes all your history is automatically wiped off but you can start another Disposable Browser session.',
          },
        ],
      },
      {
        q: 'When does my browser history get disposed or deleted? ',
        a: [
          {
            type: 'text',
            content:
              'Your browsing history gets deleted either when you close the Disposable Browser by clicking on X that is next to the country name or when the timer runs out. ',
          },
        ],
      },
      {
        q: 'Can I add my favourite extensions to the Disposable Browser?',
        a: [
          {
            type: 'text',
            content:
              'No, we don’t allow adding additional extensions to the Disposable Browser for security reasons. To maintain isolated browser sessions, the installation of third-party extensions is not allowed. We prioritise your safety',
          },
        ],
      },
      {
        q: 'What happens when I click and open a malicious website inside Disposable Browser?',
        a: [
          {
            type: 'text',
            content:
              'Nothing! You and your device are protected from any harm since the malware will be contained within the Disposable Browser. Simply dispose the session and start afresh.',
          },
        ],
      },
      {
        q: 'Can I use Disposable Browser for online banking? ',
        a: [
          {
            type: 'text',
            content:
              'Yes, the Disposable Browser is like a normal browser and you can do anything inside it.',
          },
        ],
      },
      {
        q: 'Why do I not see any ads inside Disposable Browser?',
        a: [
          {
            type: 'text',
            content:
              'We have installed an ad blocker to ensure your browsing experience is smooth and uninterrupted.',
          },
        ],
      },
      {
        q: "Why do I see 'Open with SquareX' when I right click on links? ",
        a: [
          {
            type: 'text',
            content:
              'Disposable browsers are seamlessly integrated with your regular browser through the context menu. Simply hover over a link, right click and open it in the location of your choice.',
          },
          {
            type: 'image',
            content: '/faq/db8.png',
          },
          {
            type: 'text',
            content:
              'If you wish to turn this off, click on the gear icon next to the Disposable Browser header, and use this toggle to enable or disable it.',
          },
          {
            type: 'image',
            content: '/faq/general3.png',
          },
          {
            type: 'image',
            content: '/faq/db9.png',
          },
        ],
      },
    ],
  },
  {
    title: 'Disposable File Viewer',
    qas: [
      {
        q: 'What is the Disposable File Viewer, and why do I need it?',
        a: [
          {
            type: 'text',
            content:
              'Disposable or Burner File Viewer allows you to open any type of document from untrustworthy websites or users without the fear of getting hacked. Our unique isolation technology ensures that all viruses, malware, etc remain inside the Disposable File Viewer and never get the chance to affect your device. Your personal file viewer also aims to provide a layer of anonymity and security.',
          },
        ],
      },
      // {
      //   q: 'Can you see what files I open inside the Disposable File viewer?',
      //   a: [
      //     {
      //       type: 'text',
      //       content:
      //         'Disposable or Burner File Viewer allows you to open any type of document from untrustworthy websites or users without the fear of getting hacked. Our unique isolation technology ensures that all viruses, malware, etc remain inside the Disposable File Viewer and never get the chance to affect your device. Your personal file viewer also aims to provide a layer of anonymity and security.',
      //     },
      //   ],
      // },
      {
        q: 'Why do I see the pop-up every time I begin downloading a file on my device?',
        a: [
          {
            type: 'text',
            content:
              'Every time you begin downloading a file on your device, SquareX presents you an opportunity to open and view the file in the Disposable File viewer by showing you the pop-up on the bottom of the screen called “SquareX Download Protection”. The benefit of opening this file in the Disposable File Viewer is that if the file is infected, it will not be able to cause harm to you or your device. ',
          },
        ],
      },
      {
        q: 'I have edited a file in Disposable File Viewer, how do I download it?',
        a: [
          {
            type: 'text',
            content:
              'Click on the download button you see at the bottom right-hand side of the Disposable File Viewer and select the file you want to download. Please note that once the session is disposed all files are permanently deleted and there is no way to get access to them again. ',
          },
          {
            type: 'image',
            content: '/faq/dfv1.png',
          },
        ],
      },
      {
        q: 'I opened a file in the Disposable File Viewer and now cannot find it as it says the session has been Disposed. How do I retrieve the file?',
        a: [
          {
            type: 'text',
            content:
              'Apologies, when your Disposable File viewer session is disposed off or deleted all your files are deleted forever and cannot be retrieved. For your privacy and security, we delete the session and its history completely.',
          },
        ],
      },
      {
        q: 'I have a file on my laptop but want to view it in the Disposable File Viewer. How can I do so?',
        a: [
          {
            type: 'text',
            content:
              'Step 1: Click on the SquareX extension and <strong>Start</strong> a Disposable File Viewer session.',
          },
          {
            type: 'image',
            content: '/faq/dfv2.png',
          },
          {
            type: 'text',
            content:
              'Step 2: Once the session opens, drag and drop the file or click on the “Browse Files” button and select the file you want to open in the Disposable File viewer. Alternatively, you can click on the upload button on the popup.',
          },
          {
            type: 'image',
            content: '/faq/dfv3.png',
          },
        ],
      },
      {
        q: 'What happens when I click on the Dispose button?',
        a: [
          {
            type: 'text',
            content:
              'When you click on the Dispose button, all the files that you viewed are irreversibly deleted from the server. You will no longer be able to retrieve the files on the file viewer. However, files on your local machine are unaffected by this action. ',
          },
        ],
      },
      {
        q: 'What file size limitations does the Disposable File Viewer have?',
        a: [
          {
            type: 'text',
            content:
              'Disposable File viewer can handle a wide range of file sizes, and our servers are optimized to process files efficiently. Exceptionally large files may take some time to load on the file viewer.',
          },
        ],
      },
      {
        q: 'What types of files are supported by the Disposable File viewer?',
        a: [
          {
            type: 'text',
            content:
              'The Disposable File Viewer is designed to support various types of files, including but not limited to documents, images, audio files, excel sheets, etc.',
          },
        ],
      },
      {
        q: 'Why am I asked for clipboard permissions?',
        a: [
          {
            type: 'text',
            content:
              'Giving clipboard permissions allows you to copy the text from outside the disposable file viewer to inside seamlessly, and vice-versa.',
          },
        ],
      },
      {
        q: 'How can I turn off the Download Interceptor?',
        a: [
          {
            type: 'text',
            content:
              'On the extension popup, click on the gear icon next to the Disposable File Viewer header. You can disable or enable the Download Interceptor here.',
          },
          {
            type: 'image',
            content: '/faq/dfv4.png',
          },
          {
            type: 'image',
            content: '/faq/dfv5.png',
          },
        ],
      },
      {
        q: 'I do not want the Download Interceptor to appear on some of my trusted sites. Can I stop the interception on certain sites?',
        a: [
          {
            type: 'text',
            content:
              'Certaily, you can do so. On the extension popup, click on the gear icon next to the Disposable File Viewer header. Then, click on the disallowed websites tab and add the URL of the site you do not want the Download Interceptor to intercept.',
          },
          {
            type: 'image',
            content: '/faq/dfv4.png',
          },
          {
            type: 'image',
            content: '/faq/dfv6.png',
          },
          {
            type: 'image',
            content: '/faq/dfv7.png',
          },
          {
            type: 'text',
            content:
              "Alternatively, you can wait for the popup to appear when you are downloading from a trusted site, and then click on the 'Don't ask for this site' option.",
          },
          {
            type: 'image',
            content: '/faq/dfv8.png',
          },
        ],
      },
    ],
  },
  {
    title: 'Disposable Email',
    qas: [
      {
        q: 'What is Disposable Email, and why do I need it?',
        a: [
          {
            type: 'text',
            content:
              "Disposable or burner email is a temporary email address that you can use for a short time and then dispose of. It's like a secondary email that you can use when signing up for services online, so you can avoid spam on your personal email. It also reduces your exposure to phishing scams on your personal email.",
          },
        ],
      },
      {
        q: 'Can I send emails from the Disposable Inbox provided?',
        a: [
          {
            type: 'text',
            content:
              'No, you can only receive emails in the Disposable Inbox provided',
          },
        ],
      },
      {
        q: 'After how long does the Disposable Inbox get deleted?',
        a: [
          {
            type: 'text',
            content:
              'The Inbox gets deleted when you change your Disposable Email Address. Otherwise, there is no expiry for the Disposable Inbox.',
          },
        ],
      },
      {
        q: 'Can I have multiple Disposable Email Addresses simultaneously?',
        a: [
          {
            type: 'text',
            content:
              'No, you can have only one Disposable Email Address at a time.',
          },
        ],
      },
      {
        q: 'Can I change my Disposable Email Address?',
        a: [
          {
            type: 'text',
            content:
              'Yes, you can click on the regenerate to get a random Disposable Email Address. If you prefer to customise your address, you can click on the edit button and type in your prefered username. You can also select a domain from the list available to you in the dropdown.',
          },
          {
            type: 'image',
            content: '/faq/de1.png',
          },
          {
            type: 'image',
            content: '/faq/de2.png',
          },
        ],
      },
      {
        q: 'Why am I not able to generate my preferred Disposable email by editing the name and domain name?',
        a: [
          {
            type: 'text',
            content:
              'The email address might have already been taken by another user. So the same email address cannot be provided to you for safety, privacy, and anonymity reasons',
          },
        ],
      },
      {
        q: 'Can I retrieve the emails sent to the Disposable Inbox after I have changed by Disposable Email Address?',
        a: [
          {
            type: 'text',
            content:
              'Unfortunately, once you change your Disposable Email Address, the entire inbox and all emails it received are deleted forever.',
          },
        ],
      },
      {
        q: 'How can I turn off notifications for my Disposable Email?',
        a: [
          {
            type: 'text',
            content:
              'On the extension popup, click on the gear icon next to the Disposable Email header. You can toggle the notifications here.',
          },
          {
            type: 'image',
            content: '/faq/de3.png',
          },
          {
            type: 'image',
            content: '/faq/de4.png',
          },
        ],
      },
    ],
  },
];
