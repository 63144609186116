import { faqs } from './faqlist';
import { UserT } from '../../utils/types';
import React from 'react';

type Props = {
  user: UserT | undefined;
};

export default function FAQ({ user }: Props) {
  const getFAQHeadingID = (input: string) => {
    return input.replaceAll(/\s+/g, '-').toLowerCase();
  };

  const getFAQQuestionID = (input: string) => {
    return input
      .toLowerCase() // lower case
      .trim() // trim spaces in end
      .replaceAll(/[^a-z\s]/g, '') // remove everything except alphabets and space
      .replaceAll(/\s+/g, '-'); // replace space with hyphen
  };

  // useEffect(() => {
  //   if (!user) {
  //     navigate('/login');
  //     updateUserInExtension({}, 'user-logout');
  //     return;
  //   }
  // }, [user, navigate]);

  return (
    <div className="w-full pt-[100px] flex justify-center">
      <div className="max-w-[1440px] w-full px-6 vp:px-0">
        <div className="mb-10" id="faq">
          <h2 className="mb-8 text-2xl tracking-tight font-extrabold text-gray-900">
            Frequently asked questions
          </h2>
          {faqs.map((category, i) => (
            <a
              key={category.title}
              href={'#' + getFAQHeadingID(category.title)}
              className="bg-purple-800 hover:bg-button text-white text-sm font-medium mr-2 mt-2 md:mt-0 px-4 py-2 rounded inline-flex items-center justify-center"
            >
              {category.title}
            </a>
          ))}
          <p className="p-4"></p>

          {faqs.map((category, i) => (
            <React.Fragment key={category.title}>
              <h2
                className={`faq-target text-xl font-bold pt-4 pb-4 ${
                  i > 0 ? 'mt-8' : ''
                }`}
                id={getFAQHeadingID(category.title)}
              >
                {category.title}
              </h2>
              <div className="question-wrap">
                {category.qas.map((qa, j) => (
                  <details
                    className="question py-4 border-b border-[#777] mt-2 faq-target"
                    id={getFAQQuestionID(qa.q)}
                    key={qa.q}
                  >
                    <summary className="flex items-center cursor-pointer font-medium">
                      {qa.q}
                      <div className="ml-auto">
                        <svg
                          className="fill-current opacity-75 w-4 h-4 -mr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                        </svg>
                      </div>
                    </summary>
                    <div className="mt-4 leading-normal text-md">
                      {qa.a.map((block, k) => (
                        <React.Fragment key={k}>
                          {block.type === 'image' ? (
                            <img
                              src={block.content}
                              alt="figure"
                              className="inline-block w-full max-w-[500px] mt-4 mr-4"
                            />
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: block.content,
                              }}
                              className={k > 0 ? 'mt-4' : ''}
                            ></p>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </details>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="p-5 rounded mt-6 mb-12 flex items-center gradient-landing text-white">
          <div>
            Have a different question and can’t find the answer you’re looking
            for? Send us an email to{' '}
            <a href="mailto:support@sqrx.com" className="underline">
              support@sqrx.com
            </a>{' '}
            and we’ll get back to you as soon as we can.
          </div>
        </div>
      </div>
    </div>
  );
}
