import { useEffect, useState } from 'react';
import { get } from '../../utils/fetcher';
import Spinner from '../common/Spinner';
import { $id, hideElements, showElements } from '../../utils/common';

export default function WhereAmI() {
  const [loading, setLoading] = useState<boolean>(true);
  const [coordinates, setCoordinates] = useState<string>(''); // lat and lon comma separated, with no space
  const [code, setCode] = useState<string>('');
  const [country, setCountry] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    get({
      path: 'locate',
      payload: {},
    }).then(
      (response) => {
        if (!response || response.status !== 'success') {
          console.log('failed response:', response);
          return;
        }
        setCode(response.country);
        setCountry(response.name);
        setCoordinates(response.lat + ',' + response.lon);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
    // hide the header until this page is opened
    const header = $id('top-header');
    hideElements(header);
    return () => {
      showElements(header);
    };
  }, []);

  const newTab = () => {
    // opens a new tab with no address and moves focus there
    window?.open('https://google.com', '_blank')?.focus();
  };

  return (
    <>
      {loading ? (
        <div className="mt-8 w-full h-full flex items-center justify-center">
          <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
            <div className="w-full p-5">
              <h2 className="text-2xl font-bold">Where Am I?</h2>
              <p className="text py-2">Mapping your digital coordinates...</p>
              <div className="mt-6 text-center">
                <Spinner size={6} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="w-full h-full">
            <iframe
              style={{ width: '100%', height: '100%' }}
              title="map"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://maps.google.com/maps?width=100%&height=600&hl=en&q=${coordinates}&ie=UTF8&z=10&iwloc=near&output=embed`}
            ></iframe>
          </div>
          <div className="flex items-center justify-center fixed top-[50%] left-[50%] ml-[-250px] mt-[-180px]">
            <div className="p-5 flex rounded shadow-lg w-[500px] h-[360px] items-center justify-center text-center bg-white">
              <div className="w-full p-5">
                <h2 className="text-xl pb-4">
                  You are browsing the internet from
                </h2>
                <h2 className="text-2xl font-bold">{country} </h2>
                <p className="text py-2">
                  <img
                    className="inline-block"
                    style={{ filter: 'drop-shadow(1px 1px 1px #808080)' }}
                    alt=""
                    src={`/flags/${code}.png`}
                  />
                </p>
                <p className="text pt-2">
                  Powered by{' '}
                  <strong>
                    <a
                      href="https://sqrx.com"
                      className="cursor-pointer hover:underline"
                    >
                      SquareX
                    </a>
                  </strong>
                </p>
                <p className="text pt-4">
                  Visit any website with {country} IP address using this
                  Browser-in-Browser by opening a new tab.
                </p>
                <p className="pt-1">
                  <button className="underline" onClick={newTab}>
                    Click here
                  </button>{' '}
                  to create one.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
