type RequestT = {
  path: string;
  payload?: Record<string, any>;
  headers?: Record<string, string>;
};

const apiURL = process.env.REACT_APP_API_URL;

export async function get(data: RequestT) {
  const { path, payload, headers = {} } = data;

  if (!path) {
    throw new Error('Path not passed to get');
  }

  let queryString = '';

  if (payload) {
    queryString = Object.entries(payload).reduce((prev, [key, value]) => {
      const query = `${key}=${value}`;

      if (prev === '') {
        return `?${query}`;
      }

      return `${prev}&${query}`;
    }, '');
  }

  try {
    const url =
      queryString !== ''
        ? `${apiURL}${path}/${queryString}`
        : `${apiURL}${path}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    });
    const body = await response.json();

    return body;
  } catch (error) {
    console.error(error);
  }
}

export async function post(data: RequestT) {
  const { path, payload, headers = {} } = data;

  if (!path) {
    throw new Error('Path not passed to get');
  }

  try {
    const response = await fetch(`${apiURL}${path}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    });
    const body = await response.json();

    return body;
  } catch (error) {
    console.error(error);
  }
}
