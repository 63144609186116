import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { SsoT } from './types';
import Icons from './Icons';
import Spinner from '../common/Spinner';
import {
  checkConflictedProviders,
  loginWithPassword,
  sendForgotPassMail,
  signupWithPassword,
} from '../../utils/firebase';
import { checkPwdPolicy } from '../../utils/passwordPolicy';
import { validateName } from '../../utils/common';

const GENERIC_ERR_MSG = 'something went wrong, please try again later';

type FlowT =
  | 'check'
  | 'signin'
  | 'signup'
  | 'forgotPass'
  | 'resetMailSent'
  | 'verifyMailSent';

type Props = {
  // function to set custom title
  setCustomTitle: (title: string) => void;
  // error message setter
  setErrorMessages: (messages: string[]) => void;
  // indicates an SSO attempted
  activeSSO: SsoT | undefined;
  // tracks signup form mode
  isNewAccount: boolean;
  setModeToSignUp: (isSignUp: boolean) => void;
  // tracks whether user attempted the email form
  emailAttempted: boolean;
  setEmailAttempted: (value: boolean) => void;
  // function to call post auth success
  authorizeAndRedirectUser: (
    idToken: string | void | undefined
  ) => Promise<void>;
};

export default function EmailSigninForm({
  setCustomTitle,
  setErrorMessages,
  activeSSO,
  isNewAccount,
  setModeToSignUp,
  emailAttempted,
  setEmailAttempted,
  authorizeAndRedirectUser,
}: Props) {
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [flow, setFlow] = useState<FlowT>('check');
  const [loading, setLoading] = useState<boolean>(false);

  // switches between new account and login modes of form
  const switchMode = () => {
    setModeToSignUp(!isNewAccount);
  };

  // text to show in submit button
  const renderSubmitText = useMemo(() => {
    if (loading) {
      return <Spinner size={6} />;
    }
    if (flow === 'forgotPass') {
      return 'Send me reset instructions';
    }
    return isNewAccount ? 'Agree and Sign up' : 'Continue';
  }, [loading, flow, isNewAccount]);

  /**
   * Validates a given email
   * @param {string} emailStr the input email
   */
  const validateEmail = (emailStr: string) => {
    return Boolean(
      emailStr.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    );
  };

  /**
   * Masks a given email
   * @param {string} input the input email
   */
  function maskEmail(input: string) {
    return input.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
  }

  // error messages for common error codes
  const getErrorByCode = (error: string) => {
    const code = error.replace('error: ', '');
    switch (code) {
      // common errors
      case 'auth/invalid-email':
        return 'invalid email';
      // errors on sign up
      case 'auth/email-already-in-use':
        return 'email seems to be already in use, please try logging in';
      case 'auth/weak-password':
        return 'please sure there are atleast 8 chars with mix of numbers, upper/lower case alphabets and special chars';
      // errors on sign in
      case 'auth/user-disabled':
        return 'your account is disabled, please try contacting the support';
      case 'auth/user-not-found':
        return "your password is incorrect or this account doesn't exist";
      case 'auth/wrong-password':
        return "your password is incorrect or this account doesn't exist";
      // generic error
      default:
        return GENERIC_ERR_MSG;
    }
  };

  const handleInputChange =
    (stateSetter: Dispatch<SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      stateSetter(value);
    };

  const emailBlur = () => {
    // shows error message when user leaves the text field
    if (email.length > 0 && !validateEmail(email)) {
      setErrorMessages(['please enter a valid email']);
    }
  };
  const emailFocus = () => {
    // clear the error message if email is to be attempting to be edited
    if (!emailAttempted) {
      setErrorMessages([]);
    }
  };
  const formFocus = () => {
    // clear the error message if password / name fields are attempting to be edited
    if (flow === 'signin' || flow === 'signup') {
      setErrorMessages([]);
    }
  };

  // resets all states to initial
  const resetToInit = () => {
    setEmail('');
    setName('');
    setPassword('');
    setModeToSignUp(true);
    setEmailAttempted(false);
    setFlow('check');
    setCustomTitle('');
    setLoading(false);
  };

  // switch to forgot password mode
  const switchToForgotPass = () => {
    setEmailAttempted(true);
    setModeToSignUp(false);
    setFlow('forgotPass');
    setCustomTitle('Password reset');
  };

  // logic when user submits in the email form
  const submitUsingEmail = async (event: any) => {
    if (loading) {
      return;
    }
    event.preventDefault();
    // cross check email correct
    if (!validateEmail(email)) {
      return;
    }
    if (flow === 'check') {
      // email check flow
      setEmailAttempted(true); // hide sso buttons
      setLoading(true);
      try {
        const providerInfo = await checkConflictedProviders(email, 'password');
        if (providerInfo.present) {
          setModeToSignUp(false);
          setFlow('signin');
          setLoading(false);
        } else if (providerInfo.provider === '') {
          // no existing provider
          setModeToSignUp(true);
          setRepeatPassword('');
          setFlow('signup');
          setLoading(false);
        } else {
          // show message to login using existing provider
          setErrorMessages([
            'account exists with another login method, please login using ' +
              providerInfo.provider,
          ]);
          // revert to initial state
          resetToInit();
        }
      } catch (error) {
        setErrorMessages([GENERIC_ERR_MSG]);
        setLoading(false);
      }
    } else if (flow === 'signin') {
      // login flow
      if (!password) {
        return;
      }
      setLoading(true);
      let response = await loginWithPassword(email, password);
      if (!response) {
        setErrorMessages([GENERIC_ERR_MSG]);
        setLoading(false);
        return;
      } else if (typeof response === 'string') {
        setErrorMessages([getErrorByCode(response)]);
        setLoading(false);
        return;
      }
      const { idToken, emailVerified } = response;
      if (!emailVerified) {
        setModeToSignUp(false);
        setCustomTitle('Verify your email address');
        // TODO: call an API here of backend to retrigger verification email
        setFlow('verifyMailSent');
        setLoading(false);
        return;
      }
      if (idToken && idToken.includes('error:')) {
        setErrorMessages([getErrorByCode(idToken)]);
        setLoading(false);
        return;
      }
      await authorizeAndRedirectUser(idToken);
    } else if (flow === 'signup') {
      // sign up flow

      // check for empty fields
      const emptyFields: string[] = [];
      if (!email) emptyFields.push('email');
      if (!password) emptyFields.push('password');
      if (!repeatPassword) emptyFields.push('repeat password');
      if (!name) emptyFields.push('name');
      if (emptyFields.length > 0) {
        setErrorMessages([`${emptyFields.join(', ')} cannot be empty`]);
        return;
      }
      // check for strong password
      const failList = checkPwdPolicy(password, email);
      if (failList.length > 0) {
        // show error message
        setErrorMessages(failList);
        return;
      }
      // check repeat password
      if (password !== repeatPassword) {
        setErrorMessages([`repeat password should match with password`]);
        return;
      }
      // validate name
      const nameErrMsg = validateName(name);
      if (nameErrMsg !== undefined) {
        setErrorMessages([nameErrMsg]);
        return;
      }
      setLoading(true);
      let response = await signupWithPassword(email, password, name);
      setLoading(false);
      if (!response) {
        setErrorMessages([GENERIC_ERR_MSG]);
        return;
      } else if (typeof response === 'string') {
        setErrorMessages([getErrorByCode(response)]);
        return;
      }
      const { idToken, emailVerified } = response;
      if (!emailVerified) {
        setModeToSignUp(false);
        setCustomTitle('Verify your email address');
        setFlow('verifyMailSent');
        return;
      }
      if (idToken && idToken.includes('error:')) {
        setErrorMessages([getErrorByCode(idToken)]);
        return;
      }
      await authorizeAndRedirectUser(idToken);
    } else if (flow === 'forgotPass') {
      // forgot password flow
      setLoading(true);
      const result = await sendForgotPassMail(email);
      setLoading(false);
      setModeToSignUp(false);
      if (!result) {
        setErrorMessages([GENERIC_ERR_MSG]);
      } else {
        setFlow('resetMailSent');
      }
    } else {
      console.log('invalid flow');
    }
  };

  return (
    <>
      <form className="mt-3" action="#" method="POST" id="email-form">
        <div>
          {/* show email input unless its a mail sent message */}
          {flow !== 'resetMailSent' && flow !== 'verifyMailSent' ? (
            <input
              type="email"
              placeholder="Email address"
              readOnly={emailAttempted}
              onBlur={emailBlur}
              onFocus={emailFocus}
              value={email}
              onChange={handleInputChange(setEmail)}
              autoComplete="username"
              autoFocus
              className="w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
            />
          ) : (
            ''
          )}
          {/* show below in case of sign in and sign up flow */}
          {flow === 'signin' || flow === 'signup' ? (
            <>
              <div className="text-left mt-3 text-sm font-semibold text-gray-700 underline">
                <button
                  type="button"
                  className="underline"
                  onClick={resetToInit}
                >
                  Change Email
                </button>
              </div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onFocus={formFocus}
                onChange={handleInputChange(setPassword)}
                className="w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                autoComplete={
                  flow === 'signin' ? 'current-password' : 'new-password'
                }
                required
              />
            </>
          ) : (
            ''
          )}
          {/* show below only for signup flow */}
          <input
            type="password"
            placeholder="Repeat Password"
            value={repeatPassword}
            onFocus={formFocus}
            onChange={handleInputChange(setRepeatPassword)}
            className={`w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none ${
              flow === 'signup' ? '' : 'hidden'
            }`}
            autoComplete="new-password"
            required
          />
          <input
            type="text"
            placeholder="Your name"
            value={name}
            onFocus={formFocus}
            onChange={handleInputChange(setName)}
            className={`w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none ${
              flow === 'signup' ? '' : 'hidden'
            }`}
            required
          />
        </div>
        {/* links/text above the email submit button */}
        {isNewAccount ? (
          <div className="text-sm mt-2">
            By signing up, you agree to the&nbsp;
            <a
              className="underline"
              href="https://sqrx.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a
              className="underline"
              href="https://sqrx.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        ) : (
          ''
        )}
        {/* message for reset mail sent */}
        {flow === 'resetMailSent' ? (
          <div className="py-3 text-sm">
            <div className="py-4 text-center">
              <Icons.Checkmark width="35" height="35" />
            </div>
            <p>
              If there's a SquareX account connected with this email address,
              we'll email you password reset instructions.
            </p>
            <p>
              <br />
            </p>
            <p>If you don't receive the email, please try again.</p>
          </div>
        ) : (
          ''
        )}
        {/* message for verification mail sent */}
        {flow === 'verifyMailSent' ? (
          <div className="py-3 text-sm">
            <div className="py-4 text-center">
              <Icons.Checkmark width="35" height="35" />
            </div>
            <p>
              Thanks for signing up, we just need to verify your email address.
            </p>
            <p>
              <br />
            </p>
            <p>
              An email with instructions to verify your email address has been
              sent to [{maskEmail(email)}].
            </p>
          </div>
        ) : (
          ''
        )}
        {/* show submit button unless its a mail sent message */}
        {flow !== 'resetMailSent' && flow !== 'verifyMailSent' ? (
          <button
            id="login-email-btn"
            type="submit"
            onClick={submitUsingEmail}
            className={`${
              activeSSO ? 'opacity-50 cursor-not-allowed ' : ' '
            }w-full inline-block align-middle text-white font-semibold rounded px-4 py-3 mt-6 bg-purple-800 hover:bg-button`}
            disabled={(activeSSO || loading) && true}
          >
            {renderSubmitText}
          </button>
        ) : (
          ''
        )}
        {/* links/text below the email submit button */}
        <div
          className={`text-center mt-3 text-sm font-semibold text-gray-700 ${
            flow === 'check' ? '' : 'hidden'
          }`}
        >
          <button className="underline" onClick={switchMode} type="button">
            {isNewAccount ? 'I have an account' : "I don't have an account"}
          </button>
        </div>
        <div
          className={`text-center mt-3 text-sm font-semibold text-gray-700 ${
            flow === 'signin' ? '' : 'hidden'
          }`}
        >
          <button
            className="underline"
            onClick={switchToForgotPass}
            type="button"
          >
            Reset Password
          </button>
        </div>
        <div
          className={`text-center mt-3 text-sm font-semibold text-gray-700 ${
            flow === 'forgotPass' ||
            flow === 'verifyMailSent' ||
            flow === 'resetMailSent'
              ? ''
              : 'hidden'
          }`}
        >
          <button type="button" className="underline" onClick={resetToInit}>
            Back to Login
          </button>
        </div>
      </form>
    </>
  );
}
