import { useEffect } from 'react';
import Spinner from '../common/Spinner';
import getStoreLink, { sanitizeUTMField } from '../../utils/browserSupport';
import { useNavigate } from 'react-router-dom';
import contactExtension from '../../utils/extensionInstalled';
import { PlatformType } from '../../utils/types';

export default function Launch() {
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    // save uid
    const uid = queryParams.get('uid')?.trim();
    if (uid) {
      localStorage.setItem('uid', uid);
    }
    // save sq_id
    const sq_id = queryParams.get('sq_id')?.trim();
    if (sq_id) {
      localStorage.setItem('sq_id', sq_id);
    }
    const destWebApp =
      sanitizeUTMField(queryParams.get('dest')) === PlatformType.WebApp;
    // explicit temporary destination specified as extension
    const tempDestStore =
      sanitizeUTMField(queryParams.get('dest')) === PlatformType.Extension;
    if (!localStorage.getItem('platform') && !tempDestStore) {
      // set platform if not available
      localStorage.setItem(
        'platform',
        destWebApp ? PlatformType.WebApp : PlatformType.Extension
      );
    }
    if (destWebApp) {
      sessionStorage.setItem('currentPlatform', PlatformType.WebApp);
    } else if (!tempDestStore) {
      sessionStorage.setItem('currentPlatform', PlatformType.Extension);
    }
    const source = sanitizeUTMField(queryParams.get('utm_source'));
    if (
      !localStorage.getItem('utm') ||
      (localStorage.getItem('utm_source') === 'homepage' &&
        source &&
        source !== 'homepage')
    ) {
      // write if utm details doesn't exist in local storage - we don't want to override with new values so that we can track the first source
      const medium = sanitizeUTMField(queryParams.get('utm_medium'));
      const campaign = sanitizeUTMField(queryParams.get('utm_campaign'));
      const utm_id = sanitizeUTMField(queryParams.get('utm_id'));
      const utm_term = sanitizeUTMField(queryParams.get('utm_term'));
      const utm_content = sanitizeUTMField(queryParams.get('utm_content'));
      if (source || medium || campaign) {
        source && localStorage.setItem('utm_source', source);
        medium && localStorage.setItem('utm_medium', medium);
        campaign && localStorage.setItem('utm_campaign', campaign);
        utm_id && localStorage.setItem('utm_id', utm_id);
        utm_term && localStorage.setItem('utm_term', utm_term);
        utm_content && localStorage.setItem('utm_content', utm_content);
        localStorage.setItem('utm', 'true');
      }
    }
    if (destWebApp && !tempDestStore) {
      // take to login directly
      navigate('/login');
      return;
    }
    const redirectToRequired = (forceChrome: boolean) => {
      // redirect to the required url
      const redirectURL = getStoreLink(forceChrome);
      if (redirectURL) {
        window.location.replace(redirectURL);
      } else {
        navigate('/login');
      }
    };
    if (tempDestStore) {
      // in case of temp dest store, directly take to chrome store
      // this happens when someone clicks on stars on website
      redirectToRequired(true);
      return;
    }
    contactExtension()
      .then((installed) => {
        if (installed) {
          // extension is installed take to login page
          navigate('/login');
        } else {
          redirectToRequired(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        redirectToRequired(false);
      });
  }, [navigate]);

  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-5">
          <h2 className="text-2xl font-bold">Hi there!</h2>
          <p className="text py-2">
            Just a moment while we set the stage for an amazing journey
          </p>
          <div className="mt-6 text-center">
            <Spinner size={6} />
          </div>
        </div>
      </div>
    </div>
  );
}
