// getCleanBrowserName returns a clean browser name
// if not able to figure out, it returns "your browser"
export function getCleanBrowserName() {
  if ('brave' in navigator) {
    return 'Brave';
  }
  return (function (agent) {
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge Legacy';
      case agent.indexOf('edg/') > -1:
        return 'Edge';
      case agent.indexOf('opr/') > -1:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!window.chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'Internet Explorer';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      // can't detect Vivaldi currently, will detect as Chrome: https://vivaldi.com/blog/user-agent-changes/
      default:
        return 'your browser';
    }
  })(window.navigator.userAgent.toLowerCase());
}

// iOS checks for ios
export function isIOS() {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) || // fails on iPad iOS 13
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

// isBrowserSupported returns if a given clean browser string is supported or not
export function isBrowserSupported(currentBrowser: string) {
  if (
    currentBrowser === 'Firefox' &&
    process.env.REACT_APP_ENABLE_FIREFOX === 'true'
  )
    return true;
  return ['Chrome', 'Edge', 'Brave'].includes(currentBrowser);
}

const CHROME_WEB_STORE_LINK =
  'https://chromewebstore.google.com/detail/squarex-be-secure-anonymo/kapjaoifikajdcdehfdlmojlepfpkpoe';

const EDGE_ADDON_LINK =
  'https://microsoftedge.microsoft.com/addons/detail/squarex-be-secure-anony/ajjhfmcoicneeegkmgdpnemblemdcebf';

const FIREFOX_ADDON_LINK = 'https://addons.mozilla.org/en-US/firefox/addon/'; // TODO: change this to SquareX once published

// returns store redirect URL to use
export default function getStoreLink(forceChrome: boolean) {
  const browserName = getCleanBrowserName();
  if (forceChrome) {
    return CHROME_WEB_STORE_LINK + getParams();
  }
  switch (browserName) {
    case 'Edge':
      return EDGE_ADDON_LINK + getParams();
    case 'Firefox':
      return FIREFOX_ADDON_LINK + getParams();
    default:
      return CHROME_WEB_STORE_LINK + getParams();
  }
}

// getParams returns parameters to append with question mark
export function getParams() {
  if (localStorage.getItem('utm')) {
    const utm_source = sanitizeUTMField(localStorage.getItem('utm_source'));
    const utm_medium = sanitizeUTMField(localStorage.getItem('utm_medium'));
    const utm_campaign = sanitizeUTMField(localStorage.getItem('utm_campaign'));
    const utm_id = sanitizeUTMField(localStorage.getItem('utm_id'));
    const utm_term = sanitizeUTMField(localStorage.getItem('utm_term'));
    const utm_content = sanitizeUTMField(localStorage.getItem('utm_content'));
    let params = new URLSearchParams(
      Object.assign(
        {},
        utm_source === null ? undefined : { utm_source },
        utm_medium === null ? undefined : { utm_medium },
        utm_campaign === null ? undefined : { utm_campaign },
        utm_id === null ? undefined : { utm_id },
        utm_term === null ? undefined : { utm_term },
        utm_content === null ? undefined : { utm_content }
      )
    );
    return '?' + params.toString();
  }
  return '';
}

export function sanitizeUTMField(utm: string | null) {
  if (utm === null) {
    return null;
  }
  // replace multiple spaces with underscore
  utm = utm.replace(/\s+/g, '_');
  // remove things other than _, alphabets and numbers
  utm = utm.replace(/[\W]+/g, '');
  // take first 35 characters
  if (utm.length > 35) {
    utm = utm.substring(0, 35);
  }
  // return the lower case version
  return utm.toLowerCase();
}

export function getBrowserField(
  browser: string,
  action: 'start-gif' | 'start-right' | 'pin-gif' | 'pin-right' | 'welcome'
) {
  if (browser === 'Edge') {
    switch (action) {
      case 'start-right':
        return 'right-[289px]';
      case 'start-gif':
        return '/start-edge.gif';
      case 'welcome':
        return '/sqrx-welcome-edge.png';
      case 'pin-gif':
        return '/pin-edge.gif';
      case 'pin-right':
        return 'right-[245px]';
    }
  } else if (browser === 'Firefox') {
    switch (action) {
      case 'start-right':
        return 'right-[13px]';
      case 'start-gif':
        return '/start-firefox.gif';
      case 'welcome':
        return '/sqrx-welcome-ff.png';
      case 'pin-gif':
        return '/pin-firefox.gif';
      case 'pin-right':
        return 'right-[13px]';
    }
  }
  // default
  switch (action) {
    case 'start-right':
      return 'right-[104px]';
    case 'start-gif':
      return '/start.gif';
    case 'welcome':
      return '/sqrx-welcome.png';
    case 'pin-gif':
      return '/pin.gif';
    case 'pin-right':
      return 'right-[70px]';
  }
  return '';
}
