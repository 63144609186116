import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/fetcher';
import Spinner from '../common/Spinner';
import { UserT } from '../../utils/types';
import { maskEmail, validateName } from '../../utils/common';
import { sendForgotPassMail } from '../../utils/firebase';

type Props = {
  user: UserT | undefined;
  updateName: (name: string) => void;
};

export default function Profile({ user, updateName }: Props) {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [resetState, setResetState] = useState<'to_send' | 'sent' | 'failed'>(
    'to_send'
  );

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    if (!user.isVerified) {
      navigate('/login/verify');
      return;
    }
    setName(user.name ? user.name : '');
  }, [user, navigate]);

  const GENERIC_ERR_MSG =
    "we couldn't update your profile, please try again later";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuccess(false);
    const value = e.target?.value;
    setName(value);
  };

  const resetError = () => {
    setError('');
  };

  const updateProfile = async (event: any) => {
    if (loading) {
      return;
    }
    event.preventDefault();
    if (!user || !name) {
      return;
    }
    const nameErrMsg = validateName(name);
    if (nameErrMsg !== undefined) {
      setError(nameErrMsg);
      return;
    }
    try {
      setLoading(true);
      const response: {
        status: 'success' | 'failed';
      } = await post({
        path: 'api/update',
        payload: {
          property: 'name',
          value: name,
        },
      });
      if (response && response.status === 'success') {
        updateName(name);
        setLoading(false);
        setSuccess(true);
      } else if (response.status === 'failed') {
        setError(GENERIC_ERR_MSG);
      } else {
        setError(GENERIC_ERR_MSG);
      }
      setLoading(false);
    } catch (error) {
      setError(GENERIC_ERR_MSG);
      console.error('Failed to make verification call', error);
      setLoading(false);
    }
  };

  const changePassword = async () => {
    if (user?.email) {
      const result = await sendForgotPassMail(user.email);
      if (result) {
        setResetState('sent');
      } else {
        setResetState('failed');
      }
    }
  };

  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white px-5 py-3 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-3">
          <h2 className="text-2xl font-bold">Update Profile</h2>
          <div>
            <form className="mt-6" action="#" method="POST">
              {error !== undefined && (
                <div className="text-sm text-red-500 mb-2">{error}</div>
              )}
              <div>
                <input
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={handleInputChange}
                  onFocus={resetError}
                  autoFocus
                  required
                  className="w-full px-4 py-3 rounded bg-white border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </div>
              <button
                id="update-profile"
                type="submit"
                className={`w-full inline-block align-middle font-semibold rounded px-4 py-3 mt-6 ${
                  success
                    ? 'bg-gray-500 text-gray-700'
                    : 'bg-purple-800 text-white hover:bg-button'
                }`}
                onClick={updateProfile}
                disabled={loading || success}
              >
                {!user || loading ? (
                  <Spinner size={6} />
                ) : success ? (
                  'Updated!'
                ) : (
                  'Update Name'
                )}
              </button>
              {user?.idp && user.idp === 'password' && (
                <>
                  <div className="text-sm py-2 text-center">OR</div>
                  <div className="text-center text-sm font-semibold text-gray-700">
                    <button
                      className="underline"
                      onClick={changePassword}
                      type="button"
                      disabled={resetState === 'sent'}
                    >
                      {resetState === 'sent'
                        ? `Reset instructions sent to ${maskEmail(
                            user?.email ?? ''
                          )}`
                        : resetState === 'failed'
                        ? "Couldn't initiate password change, please try again later"
                        : 'Change Password?'}
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
