import { toTitleCase } from '../../utils/common';
import Spinner from '../common/Spinner';
import Icons from './Icons';
import { SsoT } from './types';

type Props = {
  method: SsoT;
  activeSSO: SsoT | undefined;
  isNewAccount: boolean;
  handleSsoClick: (method: SsoT) => () => Promise<void>;
};

const buttonClass =
  'inline-block align-middle bg-white border py-3 px-4 w-full rounded mt-3 flex items-left text-sm text-lightText-heading hover:bg-gray-500 duration-300';

export default function SsoLoginButton({
  method,
  activeSSO,
  isNewAccount,
  handleSsoClick,
}: Props) {
  const GoogleIcon = <Icons.Google width="18" height="18" />;
  const TwitterIcon = <Icons.Twitter width="18" height="18" />;
  const FacebookIcon = <Icons.Facebook width="18" height="18" />;
  const MicrosoftIcon = <Icons.Microsoft width="18" height="18" />;

  const iconsMap: Record<SsoT, JSX.Element> = {
    google: GoogleIcon,
    twitter: TwitterIcon,
    facebook: FacebookIcon,
    microsoft: MicrosoftIcon,
  };

  return (
    <div>
      <button
        className={buttonClass}
        onClick={handleSsoClick(method)}
        id={`login-${method}-btn`}
      >
        {activeSSO === method ? <Spinner size={5} /> : iconsMap[method]}
        <span className="ml-4">
          {activeSSO === method
            ? 'Logging in...'
            : `${isNewAccount ? 'Sign up' : 'Continue'} with ${toTitleCase(
                method
              )}`}
        </span>
      </button>
    </div>
  );
}
