type Props = {
  heading: string;
};

export default function QuotaExhausted({ heading }: Props) {
  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white p-5 flex rounded shadow-lg max-w-[550px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-5">
          <h2 className="text-2xl font-bold">Oops! {heading} Reached</h2>
          <p className="text py-2">
            Uh-oh! You've hit your {heading.toLowerCase()}
          </p>
          <p className="text py-2">
            <strong>Disposable Browser</strong>&nbsp;and&nbsp;
            <strong>Disposable File Viewer</strong>&nbsp;feature will be
            temporarily unavailable until your limit resets at 00:00 UTC. You
            can continue to use&nbsp;
            <strong>Disposable Email</strong>&nbsp;until then.
          </p>
          <p className="text py-2">Thanks for your understanding!</p>
        </div>
      </div>
    </div>
  );
}
