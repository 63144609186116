import { post } from './fetcher';
import { PlatformType, UserAuthResponse } from './types';

export const WEB_APP_URL = process.env.REACT_APP_API_URL + 'web/';
export const PWA_URL = process.env.REACT_APP_API_URL + 'pwa/';

export async function authorizeUser(
  token?: string | undefined
): Promise<UserAuthResponse> {
  // pick utm details from local storage if token is defined
  let utm_source: string | null = null,
    utm_medium: string | null = null,
    utm_campaign: string | null = null,
    utm_id: string | null = null,
    utm_term: string | null = null,
    utm_content: string | null = null,
    language: string | null = null;
  // preferred platform tracker
  let platform: string | undefined;
  let uid: string | null = null;
  let sq_id: string | null = null;
  if (token) {
    utm_source = localStorage.getItem('utm_source');
    utm_medium = localStorage.getItem('utm_medium');
    utm_campaign = localStorage.getItem('utm_campaign');
    utm_id = localStorage.getItem('utm_id');
    utm_term = localStorage.getItem('utm_term');
    utm_content = localStorage.getItem('utm_content');
    const storagePlatform = localStorage.getItem('platform');
    if (
      storagePlatform === PlatformType.Extension ||
      storagePlatform === PlatformType.WebApp
    ) {
      platform = storagePlatform;
    }
    language = navigator.language;
    uid = localStorage.getItem('uid');
    sq_id = localStorage.getItem('sq_id');
  }
  let payload = Object.assign(
    {},
    token === undefined ? undefined : { token },
    utm_source === null ? undefined : { utm_source },
    utm_medium === null ? undefined : { utm_medium },
    utm_campaign === null ? undefined : { utm_campaign },
    utm_id === null ? undefined : { utm_id },
    utm_term === null ? undefined : { utm_term },
    utm_content === null ? undefined : { utm_content },
    { platform },
    language === null ? undefined : { language },
    uid === null ? undefined : { uid },
    sq_id === null ? undefined : { sq_id }
  );
  const response = post({
    path: 'api/authorize',
    payload,
  });
  return response;
}
