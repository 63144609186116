import { useEffect, useMemo, useState } from 'react';
import { post } from '../../utils/fetcher';
import Spinner from '../common/Spinner';
import {
  disconnectSocket,
  relayCode,
  setupSocketInput,
} from '../../utils/fileShareSocket';
import Icons from '../login/Icons';

type signedURLResponse = {
  expiry: number;
  name: string;
  session: string;
  requires_password: boolean;
};

export default function Share() {
  const [error, setError] = useState<boolean>(false);
  const [incorrect, setIncorrect] = useState<boolean>(false);
  const [socketConnecting, setSocketConnecting] = useState<boolean>(false);
  const [passcode, setPasscode] = useState<string>('');
  const [apiResponse, setAPIResponse] = useState<
    signedURLResponse | undefined
  >();

  const apiURL = process.env.REACT_APP_API_URL;

  const getSignedURL = async (shareID: string): Promise<signedURLResponse> => {
    const response: any = await post({
      path: `api/create_file_sharing_session/${shareID}`,
      payload: {},
    });
    if (response.status !== 'success') {
      throw new Error('failed to fetch session');
    }
    return {
      expiry: Number(response.expiry),
      name: response.name,
      session: response.session,
      requires_password: response.requires_password
        ? response.requires_password
        : false,
    };
  };

  const onPasscodeInput = () => {
    const inputPass = (document.getElementById('passcode') as HTMLInputElement)
      .value;
    setPasscode(inputPass);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const shareID = urlParams.get('id');
    if (!shareID) {
      setError(true);
      return;
    }

    getSignedURL(shareID)
      .then((response: signedURLResponse) => {
        setAPIResponse(response);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  const sessionURL = useMemo<string>(() => {
    return `${apiURL}display/?type=file_viewer&session=${apiResponse?.session}`;
  }, [apiResponse?.session, apiURL]);

  useEffect(() => {
    // setup event
    const eventHandler = (e: any) => {
      if (e.detail === true) {
        // success case
        disconnectSocket();
        setSocketConnecting(false);
        // open link
        window.location.href = sessionURL;
      } else {
        setSocketConnecting(false);
        setIncorrect(true);
      }
    };
    document.addEventListener('zip_attempt', eventHandler, false);
    return () => {
      document.removeEventListener('zip_attempt', eventHandler);
    };
  }, [sessionURL]);

  const expiredDate = useMemo<string>(() => {
    if (apiResponse?.expiry === undefined) {
      return '';
    }
    const dateObject = new Date(apiResponse.expiry * 1000);
    return dateObject.toLocaleString();
  }, [apiResponse?.expiry]);

  const openLink = () => {
    setIncorrect(false);
    if (apiResponse?.session !== undefined) {
      // send password in socket if required
      if (apiResponse.requires_password) {
        setSocketConnecting(true);
        setupSocketInput(apiResponse.session, passcode);
        relayCode();
      } else {
        // open the url
        window.location.href = sessionURL;
      }
    }
  };

  return (
    <div className="border-red-500 mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white px-5 py-3 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-3">
          <h2 className="text-2xl font-bold pb-5">Disposable File Sharing</h2>
          <div>
            {apiResponse === undefined && !error ? (
              <div className="w-full flex justify-center">
                <div className="max-w-[50px] p-4">
                  <Spinner size={5} />
                </div>
              </div>
            ) : (
              <>
                {error ? (
                  <>
                    <p className="px-4 py-8 w-full flex justify-center">
                      <Icons.Warning width="50" height="50" />
                    </p>
                    <p className="pb-8 w-full flex justify-center ">
                      File is disposed or Incorrect URL
                    </p>
                  </>
                ) : (
                  <>
                    <p className="px-4 py-8 w-full flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1"
                        className="inline-block text-gray-400"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>
                    </p>
                    <p className="pb-4">File Name: {apiResponse?.name}</p>
                    <p className="pb-4">Expires on: {expiredDate}</p>
                    {apiResponse?.requires_password ? (
                      <p className="pb-4">
                        <input
                          type="password"
                          placeholder="Enter passcode here"
                          id="passcode"
                          onInput={onPasscodeInput}
                          autoFocus
                          className="w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                        />
                      </p>
                    ) : (
                      ''
                    )}
                    <div className="pb-6">
                      <button
                        className="w-full inline-block align-middle text-white font-semibold rounded px-4 py-3 mt-6 bg-purple-800 hover:bg-button disabled:bg-purple-300"
                        disabled={
                          (apiResponse?.requires_password && passcode === '') ||
                          socketConnecting
                        }
                        onClick={openLink}
                      >
                        {socketConnecting ? 'Opening...' : 'Open File'}
                      </button>
                      {incorrect ? (
                        <div className="w-full text-center mt-2 text-sm text-red-500">
                          Incorrect Password
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            <p className="text pt-2">
              <a href="https://sqrx.com" className="hover:underline">
                <strong>SquareX</strong>
              </a>
              &apos;s Disposable File Viewer allows you to share expirable
              documents securely.{' '}
              <a href="https://sqrx.com" className="underline">
                Sign up
              </a>{' '}
              to try today.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
