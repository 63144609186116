async function attemptRuntimeExtensionComms(): Promise<boolean> {
  // This will take 750ms if there's no runtime availability.
  let remaining_attempts = 3;
  while (remaining_attempts > 0) {
    if (!window.chrome?.runtime) {
      await new Promise((r) => setTimeout(r, 250));
      remaining_attempts--;
      continue;
    }
    return true;
  }
  throw new Error('Runtime extension comms unavailable');
}

const CommsCheckRequest = 'VR_COMMS_CHECK_REQUEST';
const CommsCheckResponse = 'VR_COMMS_CHECK_RESPONSE';

async function attemptCustomEventMessageComms() {
  if (process.env.REACT_APP_ENABLE_FIREFOX !== 'true') {
    throw new Error('Firefox disabled');
  }
  const result = await new Promise((resolve) => {
    const timer = window.setTimeout(() => {
      document.removeEventListener(CommsCheckResponse, fn);
      resolve(false);
    }, 750);
    const fn = () => {
      window.clearTimeout(timer);
      document.removeEventListener(CommsCheckResponse, fn);
      resolve(true);
    };
    document.addEventListener(CommsCheckResponse, fn);
    document.dispatchEvent(new CustomEvent(CommsCheckRequest));
  });
  if (!result) {
    throw new Error('Custom event comms unavailable');
  }
}

export default async function contactExtension(): Promise<boolean> {
  const results = await Promise.allSettled([
    attemptCustomEventMessageComms(),
    attemptRuntimeExtensionComms(),
  ]);
  return results[0].status === 'fulfilled' || results[1].status === 'fulfilled';
}
