import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import Spinner from '../common/Spinner';
import { verifyPasswordResetCode, applyActionCode, Auth } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { checkPwdPolicy } from '../../utils/passwordPolicy';
import Icons from '../login/Icons';
import { maskEmail } from '../../utils/common';

const { REACT_APP_API_URL, REACT_APP_FB_API_KEY } = process.env;

export default function AuthAction() {
  const navigate = useNavigate();
  const [mode, setMode] = useState<string>('');
  const [actionCode, setActionCode] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [messages, setMessages] = useState<string[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [terminal, setTerminal] = useState<'success' | 'failed' | undefined>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlMode = urlParams.get('mode');
    const urlActionCode = urlParams.get('oobCode');
    setMode(urlMode || '');
    setActionCode(urlActionCode || '');
    switch (urlMode) {
      case 'resetPassword':
        handleResetPassword(auth, urlActionCode || '');
        break;
      case 'verifyEmail':
        handleVerifyEmail(auth, urlActionCode || '');
        break;
      default:
        setTitle('Invalid Page Mode');
        setMessages(['Are you lost?']);
        setLoading(false);
    }
  }, []);

  const handleResetPassword = async (auth: Auth, actionCode: string) => {
    await verifyPasswordResetCode(auth, actionCode)
      .then((resEmail) => {
        setTitle('Reset your password');
        setEmail(resEmail);
        setMessages([`for ${maskEmail(resEmail)}`]);
        setShowPasswordInput(true);
        setTerminal(undefined);
        setLoading(false);
      })
      .catch((error) => {
        setTitle('Try resetting your password again');
        setMessages([
          'Oops! It seems like the reset password link has expired or has already been used.',
        ]);
        setTerminal('failed');
        setLoading(false);
      });
  };

  const handleVerifyEmail = async (auth: Auth, actionCode: string) => {
    await applyActionCode(auth, actionCode)
      .then((resp) => {
        // Email address has been verified.
        setTitle('Your email has been verified');
        setMessages([
          '🎉 Congratulations! Your email has been verified',
          'Click the link below to log in and start using SquareX',
        ]);
        setTerminal('success');
        setLoading(false);
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        setTitle('Try verifying your email again');
        setMessages([
          'Oops! It seems like the verification link has expired or has already been used',
        ]);
        setTerminal('failed');
        setLoading(false);
      });
  };

  const handlePasswordChange =
    (stateSetter: Dispatch<SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      stateSetter(value);
    };

  const handleSubmit = async (event: any) => {
    if (loading || !password || !confirmPassword) {
      return;
    }
    event.preventDefault();
    const failList = checkPwdPolicy(password, email);
    if (failList.length > 0) {
      // show error message
      setErrorMessages(failList);
      return;
    }
    // check repeat password
    if (password !== confirmPassword) {
      setErrorMessages([`confirm password should match with password`]);
      return;
    }
    setLoading(true);
    const response = await fetch(REACT_APP_API_URL + 'api/reset_password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        newPassword: password,
        apiKey: REACT_APP_FB_API_KEY,
        oobCode: actionCode,
      }),
    });
    setLoading(false);
    if (response.ok) {
      setTitle('Your password has been changed');
      setMessages([
        '🔒 Your password has been successfully changed and your account is now secured with the updated credentials.',
        'You can now sign in with your new password',
      ]);
      setShowPasswordInput(false);
      setTerminal('success');
      setLoading(false);
      return;
    }

    const data = await response.json();
    const errorMessage = extractErrorMessage(data.error);
    setErrorMessages(errorMessage);
  };

  function extractErrorMessage(error: Error) {
    // Split the error message at ': ' to separate the Firebase error prefix.
    const parts = error.message.split(': ');
    if (parts.length >= 2) {
      // Remove the substring that starts with ' (auth/' to ignore the technical error code.
      const errorMessage = parts[2].split(' (auth/')[0].trim();
      return errorMessage.trim().replace('[', '').replace(']', '').split(',');
    }
    return ['An unknown error occurred']; // Fallback error message
  }

  function handleBackToLogin() {
    navigate('/login');
  }

  const formFocus = () => {
    // clear the error message if password / name fields are attempting to be edited
    setErrorMessages([]);
  };

  return (
    <>
      <div className="w-full h-full">
        <div className="mt-8 w-full flex pt-[100px] xl:pt-0 xl:h-full xl:items-center justify-center">
          <div className="bg-white px-5 py-3 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
            <div className="w-full p-3">
              {loading ? (
                <>
                  <h2 className="text-2xl font-bold text-center">
                    Please wait...
                  </h2>
                  <div className="mt-6 text-center">
                    <Spinner size={6} />
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-2xl font-bold text-center">{title}</h2>
                  <div className="text-center">
                    <div className="py-3 text-sm">
                      {terminal === 'success' && (
                        <div className="py-4">
                          <Icons.Checkmark width="35" height="35" />
                        </div>
                      )}
                      {terminal === 'failed' && (
                        <div className="py-4">
                          <Icons.Warning width="35" height="35" />
                        </div>
                      )}
                      {messages.map((message, index) => {
                        return (
                          <>
                            {index > 0 && (
                              <p>
                                <br />
                              </p>
                            )}
                            <p key={index}>{message}</p>
                          </>
                        );
                      })}
                      <div className={errorMessages.length > 0 ? 'pt-4' : ''}>
                        {errorMessages.map((error, index) => {
                          return (
                            <p className="text-red-500 text-left" key={index}>
                              {error}
                            </p>
                          );
                        })}
                      </div>
                      {mode === 'resetPassword' && showPasswordInput && (
                        <form
                          className="mt-3"
                          action="#"
                          method="POST"
                          id="email-form"
                        >
                          <input
                            type="password"
                            placeholder="New Password"
                            value={password}
                            onFocus={formFocus}
                            onChange={handlePasswordChange(setPassword)}
                            className="w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            autoComplete="new-password"
                            required
                          />
                          <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onFocus={formFocus}
                            onChange={handlePasswordChange(setConfirmPassword)}
                            className="w-full px-4 py-3 rounded bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                            autoComplete="new-password"
                            required
                          />
                          <button
                            id="login-email-btn"
                            type="submit"
                            onClick={handleSubmit}
                            className={`w-full inline-block align-middle text-white font-semibold rounded px-4 py-3 mt-6 bg-purple-800 hover:bg-button`}
                          >
                            Reset
                          </button>
                        </form>
                      )}
                    </div>
                  </div>
                  {terminal !== undefined && (
                    <div className="text-center mt-3 text-sm font-semibold text-gray-700">
                      <button
                        type="button"
                        className="underline"
                        onClick={handleBackToLogin}
                      >
                        Back to Login
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
