import { initializeApp } from 'firebase/app';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  User,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithEmailAndPassword,
  signInWithRedirect,
  updateProfile,
} from 'firebase/auth';

const {
  REACT_APP_APP_ID,
  REACT_APP_FB_API_KEY,
  REACT_APP_FB_PROJ_ID,
  REACT_APP_FB_SENDER_ID,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_API_URL
} = process.env;

const app = initializeApp({
  appId: REACT_APP_APP_ID,
  apiKey: REACT_APP_FB_API_KEY,
  projectId: REACT_APP_FB_PROJ_ID,
  authDomain: REACT_APP_FB_AUTH_DOMAIN,
  messagingSenderId: REACT_APP_FB_SENDER_ID,
});
export const auth = getAuth(app);

// utilitity functions
export async function loginWithFB() {
  const provider = new FacebookAuthProvider();
  provider.addScope('email');
  await signInWithRedirect(auth, provider);
}

export async function loginWithGoogle() {
  const provider = new GoogleAuthProvider();
  await signInWithRedirect(auth, provider);
}

export async function loginWithMicrosoft() {
  const provider = new OAuthProvider('microsoft.com');
  await signInWithRedirect(auth, provider);
}

export async function loginWithTwitter() {
  const provider = new TwitterAuthProvider();
  await signInWithRedirect(auth, provider);
}

// checkConflictedProviders returns whether the provider to check matches with email
// match check is ignored if provider is passed as blank string
// returns boolean indicating matches and a string indicating the first provider
// in case no providers are present, returns empty string as first provider
export async function checkConflictedProviders(
  email: string,
  provider: string
) {
  const response = await checkIfEmailExists(email);
  if (response.length > 0) {
    // check for input provider is present
    if (provider && response.includes(provider)) {
      return { present: true, provider: provider };
    } else {
      // show the first provider from the returned list
      return { present: false, provider: response[0] };
    }
  }
  return { present: false, provider: '' };
}

export async function checkIfEmailExists(email: string) {
  return await fetchSignInMethodsForEmail(auth, email);
}

export async function sendForgotPassMail(email: string) {
  try {
    await fetch(
      REACT_APP_API_URL + "api/send_reset_password_email",
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );
    return true;
  } catch (error) {
    return false;
  }
}

function sendVerificationMail(user: User) {
  if (user.email) {
    fetch(
      REACT_APP_API_URL + "api/send_verification_email",
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: user.email,
          uid: user.uid
        }),
      }
    );
  }
}

export async function loginWithPassword(email: string, password: string) {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const { user } = result;
    if (user) {
      const idToken = await user.getIdToken(true);
      if (!user.emailVerified) {
        sendVerificationMail(user);
      }
      return {
        idToken,
        emailVerified: user.emailVerified,
      };
    }
  } catch (error: any) {
    return `error: ${error.code}`;
  }
}

export async function signupWithPassword(
  email: string,
  password: string,
  name: string
) {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = result;
    if (user) {
      await updateProfile(user, {
        displayName: name,
      });
      sendVerificationMail(user);
      const idToken = await user.getIdToken(true);
      return {
        idToken,
        emailVerified: user.emailVerified,
      };
    }
  } catch (error: any) {
    return `error: ${error.code}`;
  }
}
