type IconProps = {
  height?: string;
  width?: string;
  lineColor?: string;
  className?: string;
};

function Google({
  height = '50',
  width = '50',
  lineColor = '#000000',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 128 128"
      height={height}
      width={width}
    >
      <g clipRule="evenodd">
        <path fill="none" d="M0 0h128v128H0z" />
        <path
          fill="#FBBC05"
          fillRule="evenodd"
          d="M27.585 64c0-4.157.69-8.143 1.923-11.881L7.938 35.648C3.734 44.183 1.366 53.801 1.366 64c0 10.191 2.366 19.802 6.563 28.332l21.558-16.503A37.86 37.86 0 0 1 27.585 64"
        />
        <path
          fill="#EA4335"
          fillRule="evenodd"
          d="M65.457 26.182c9.031 0 17.188 3.2 23.597 8.436L107.698 16C96.337 6.109 81.771 0 65.457 0 40.129 0 18.361 14.484 7.938 35.648l21.569 16.471a37.77 37.77 0 0 1 35.95-25.937"
        />
        <path
          fill="#34A853"
          fillRule="evenodd"
          d="M65.457 101.818a37.77 37.77 0 0 1-35.949-25.937L7.938 92.349C18.361 113.516 40.129 128 65.457 128c15.632 0 30.557-5.551 41.758-15.951L86.741 96.221c-5.777 3.639-13.052 5.597-21.284 5.597"
        />
        <path
          fill="#4285F4"
          fillRule="evenodd"
          d="M126.634 64c0-3.782-.583-7.855-1.457-11.636h-59.72v24.727h34.376c-1.719 8.431-6.397 14.912-13.092 19.13l20.474 15.828c11.766-10.92 19.419-27.188 19.419-48.049"
        />
      </g>
    </svg>
  );
}

function Facebook({ height = '50', width = '50' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      viewBox="0 0 512 512"
      height={height}
      width={width}
    >
      <path
        d="M512 256C512 114.615 397.385 0 256 0S0 114.615 0 256c0 127.777 93.616 233.685 216 252.89V330h-65v-74h65v-56.4c0-64.16 38.219-99.6 96.695-99.6 28.009 0 57.305 5 57.305 5v63h-32.281C305.918 168 296 187.733 296 207.978V256h71l-11.35 74H296v178.89C418.385 489.685 512 383.777 512 256Z"
        style={{
          fill: '#1877f2',
          fillRule: 'nonzero',
        }}
      />
      <path
        d="M355.65 330 367 256h-71v-48.022c0-20.245 9.917-39.978 41.719-39.978H370v-63s-29.297-5-57.305-5C254.219 100 216 135.44 216 199.6V256h-65v74h65v178.89c13.033 2.045 26.392 3.11 40 3.11s26.966-1.065 40-3.11V330h59.65Z"
        style={{
          fill: '#fff',
          fillRule: 'nonzero',
        }}
      />
    </svg>
  );
}

function Microsoft({ height = '50', width = '50' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      height={height}
      width={width}
    >
      <path fill="#F35325" d="M1 1h6.5v6.5H1V1z" />
      <path fill="#81BC06" d="M8.5 1H15v6.5H8.5V1z" />
      <path fill="#05A6F0" d="M1 8.5h6.5V15H1V8.5z" />
      <path fill="#FFBA08" d="M8.5 8.5H15V15H8.5V8.5z" />
    </svg>
  );
}

function Twitter({ height = '50', width = '50' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -4 48 48"
      height={height}
      width={width}
    >
      <path
        fill="#00AAEC"
        fillRule="evenodd"
        d="M48 4.735a19.235 19.235 0 0 1-5.655 1.59A10.076 10.076 0 0 0 46.675.74a19.395 19.395 0 0 1-6.257 2.447C38.627 1.225 36.066 0 33.231 0c-5.435 0-9.844 4.521-9.844 10.098 0 .791.085 1.56.254 2.3C15.456 11.974 8.2 7.96 3.34 1.842A10.282 10.282 0 0 0 2.01 6.925c0 3.502 1.738 6.593 4.38 8.405a9.668 9.668 0 0 1-4.462-1.26v.124c0 4.894 3.395 8.977 7.903 9.901a9.39 9.39 0 0 1-2.595.356c-.634 0-1.254-.061-1.854-.18 1.254 4.01 4.888 6.932 9.199 7.01-3.37 2.71-7.618 4.325-12.23 4.325-.795 0-1.58-.047-2.35-.139C4.359 38.327 9.537 40 15.096 40c18.115 0 28.019-15.385 28.019-28.73 0-.439-.009-.878-.026-1.308A20.211 20.211 0 0 0 48 4.735"
      />
    </svg>
  );
}

function Checkmark({ height = '50', width = '50' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      height={height}
      width={width}
      style={{ display: 'inline-block' }}
      enableBackground="new 0 0 64 64"
    >
      <path
        d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
    l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
        fill="#43a047"
      />
    </svg>
  );
}

function Profile({ height = '18', width = '18', className = '' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M11.400 2.044 C 9.761 2.229,8.261 3.290,7.526 4.785 C 7.158 5.533,7.020 6.136,7.020 7.000 C 7.020 7.670,7.078 8.044,7.268 8.600 C 7.846 10.291,9.253 11.525,11.040 11.907 C 11.511 12.008,12.489 12.008,12.960 11.907 C 14.488 11.580,15.748 10.630,16.433 9.288 C 16.838 8.494,16.980 7.899,16.980 7.000 C 16.980 6.101,16.838 5.506,16.433 4.713 C 15.794 3.461,14.604 2.510,13.240 2.162 C 12.669 2.016,12.019 1.974,11.400 2.044 M12.861 4.129 C 13.804 4.405,14.584 5.183,14.875 6.139 C 14.958 6.412,14.973 6.542,14.973 7.000 C 14.973 7.458,14.958 7.588,14.875 7.861 C 14.582 8.822,13.819 9.581,12.840 9.886 C 12.406 10.021,11.594 10.021,11.160 9.886 C 10.181 9.581,9.418 8.822,9.125 7.861 C 9.042 7.588,9.027 7.458,9.027 7.000 C 9.027 6.542,9.042 6.412,9.125 6.139 C 9.456 5.054,10.371 4.245,11.499 4.040 C 11.832 3.980,12.500 4.023,12.861 4.129 M7.400 14.044 C 5.761 14.229,4.261 15.290,3.526 16.785 C 3.344 17.154,3.194 17.581,3.097 18.000 C 3.041 18.245,3.025 18.563,3.011 19.744 C 3.000 20.701,3.008 21.243,3.037 21.348 C 3.144 21.748,3.430 21.961,3.896 21.991 C 4.381 22.022,4.699 21.882,4.880 21.558 L 4.980 21.380 5.003 19.920 C 5.026 18.520,5.031 18.447,5.125 18.139 C 5.420 17.173,6.215 16.388,7.178 16.112 L 7.500 16.020 12.000 16.020 L 16.500 16.020 16.822 16.112 C 17.785 16.388,18.580 17.173,18.876 18.139 C 18.970 18.447,18.975 18.518,18.997 19.920 L 19.020 21.380 19.120 21.558 C 19.287 21.856,19.572 22.000,20.000 22.000 C 20.428 22.000,20.713 21.856,20.880 21.558 L 20.980 21.380 20.980 19.880 C 20.980 18.581,20.970 18.335,20.907 18.040 C 20.625 16.721,19.863 15.576,18.800 14.874 C 18.046 14.375,17.396 14.135,16.552 14.043 C 16.084 13.991,7.855 13.993,7.400 14.044 "
        stroke="none"
        fillRule="evenodd"
        fill="currentColor"
      ></path>
    </svg>
  );
}

function Help({ height = '18', width = '18', className = '' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 -960 960 960"
      className={className}
    >
      <path
        d="M484-247q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm-35-146h59q0-26 6.5-47.5T555-490q31-26 44-51t13-55q0-53-34.5-85T486-713q-49 0-86.5 24.5T345-621l53 20q11-28 33-43.5t52-15.5q34 0 55 18.5t21 47.5q0 22-13 41.5T508-512q-30 26-44.5 51.5T449-393Zm31 313q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
        fill="currentColor"
      />
    </svg>
  );
}

function Mail({ height = '18', width = '18', className = '' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
    >
      <path
        d="M3.499 3.043 C 2.858 3.151,2.395 3.398,1.896 3.896 C 1.517 4.275,1.305 4.598,1.162 5.014 C 0.978 5.548,0.979 5.522,1.000 12.240 L 1.020 18.580 1.125 18.887 C 1.296 19.387,1.496 19.704,1.896 20.104 C 2.296 20.504,2.613 20.704,3.113 20.875 L 3.420 20.980 12.000 20.980 L 20.580 20.980 20.887 20.875 C 21.387 20.704,21.704 20.504,22.104 20.104 C 22.504 19.704,22.704 19.387,22.875 18.887 L 22.980 18.580 23.000 12.240 C 23.021 5.522,23.022 5.548,22.838 5.014 C 22.695 4.598,22.483 4.275,22.104 3.896 C 21.694 3.486,21.363 3.280,20.861 3.121 L 20.540 3.020 12.120 3.014 C 7.489 3.011,3.609 3.024,3.499 3.043 M20.259 5.045 C 20.468 5.102,20.722 5.281,20.809 5.430 C 20.875 5.543,20.875 5.552,20.809 5.608 C 20.588 5.797,12.051 11.737,12.000 11.737 C 11.949 11.737,3.412 5.797,3.191 5.608 C 3.125 5.552,3.125 5.543,3.191 5.430 C 3.274 5.288,3.533 5.101,3.723 5.047 C 3.926 4.988,20.049 4.986,20.259 5.045 M11.705 13.962 C 11.895 14.024,12.105 14.024,12.295 13.962 C 12.377 13.934,14.370 12.565,16.723 10.918 L 21.001 7.923 20.991 13.092 C 20.980 18.135,20.978 18.264,20.902 18.418 C 20.858 18.505,20.754 18.643,20.670 18.725 C 20.364 19.022,21.069 19.000,12.000 19.000 C 2.931 19.000,3.636 19.022,3.330 18.725 C 3.246 18.643,3.142 18.505,3.098 18.418 C 3.022 18.264,3.020 18.135,3.009 13.092 L 2.999 7.923 7.277 10.918 C 9.630 12.565,11.623 13.934,11.705 13.962 "
        stroke="none"
        fillRule="evenodd"
        fill="currentColor"
      ></path>
    </svg>
  );
}

function SignOut({ height = '18', width = '18', className = '' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M4.499 2.043 C 3.383 2.230,2.461 3.048,2.114 4.160 L 2.020 4.460 2.020 12.000 L 2.020 19.540 2.114 19.840 C 2.419 20.816,3.152 21.557,4.140 21.885 C 4.419 21.978,4.432 21.978,6.808 21.991 C 8.389 22.000,9.249 21.990,9.352 21.962 C 9.583 21.900,9.768 21.759,9.880 21.558 C 9.967 21.402,9.980 21.332,9.979 21.000 C 9.977 20.469,9.852 20.247,9.455 20.071 C 9.368 20.032,8.777 20.015,7.020 20.000 L 4.700 19.980 4.516 19.872 C 4.415 19.813,4.284 19.700,4.225 19.623 C 3.986 19.309,4.000 19.795,4.000 12.022 C 4.000 4.006,3.975 4.631,4.303 4.303 C 4.609 3.997,4.582 4.000,7.057 4.000 C 8.383 4.000,9.276 3.984,9.365 3.959 C 9.586 3.898,9.770 3.755,9.880 3.558 C 9.967 3.402,9.980 3.332,9.979 3.000 C 9.978 2.567,9.898 2.355,9.678 2.199 C 9.405 2.004,9.340 2.000,6.944 2.005 C 5.710 2.007,4.609 2.025,4.499 2.043 M15.626 6.045 C 15.420 6.154,15.146 6.433,15.038 6.644 C 14.924 6.868,14.924 7.133,15.040 7.363 C 15.100 7.483,15.679 8.092,16.853 9.270 L 18.578 11.000 13.695 11.000 C 8.462 11.000,8.608 10.995,8.322 11.199 C 8.100 11.357,8.020 11.568,8.020 12.000 C 8.020 12.331,8.033 12.403,8.120 12.558 C 8.230 12.755,8.414 12.898,8.635 12.959 C 8.729 12.985,10.520 13.000,13.679 13.000 L 18.578 13.000 16.853 14.730 C 15.679 15.908,15.100 16.517,15.040 16.637 C 14.863 16.989,14.952 17.354,15.299 17.701 C 15.646 18.048,16.010 18.137,16.363 17.960 C 16.486 17.899,17.313 17.103,19.061 15.366 C 20.448 13.988,21.634 12.826,21.696 12.784 C 21.850 12.682,22.049 12.239,22.049 12.000 C 22.049 11.744,21.851 11.321,21.676 11.201 C 21.601 11.150,20.415 9.988,19.040 8.618 C 17.379 6.964,16.483 6.100,16.372 6.044 C 16.152 5.934,15.835 5.935,15.626 6.045 "
        stroke="none"
        fillRule="evenodd"
        fill="currentColor"
      ></path>
    </svg>
  );
}

function Warning({ height = '50', width = '50', className = '' }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
      style={{ display: 'inline-block' }}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  );
}

const Icons = {
  Checkmark,
  Microsoft,
  Facebook,
  Twitter,
  Google,
  Profile,
  Help,
  Mail,
  SignOut,
  Warning,
};

export default Icons;
