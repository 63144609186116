import { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
  vimeo: string;
  videoWidth: number;
  className?: string;
  children: React.ReactNode;
}

export default function VideoModalButton({
  vimeo,
  videoWidth,
  className,
  children,
}: Props) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <>
      <button
        className={className}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {children}
      </button>

      <Transition
        show={modalOpen}
        as={Fragment}
        afterEnter={() => videoRef.current?.play()}
      >
        <Dialog initialFocus={videoRef} onClose={() => setModalOpen(false)}>
          {/* Modal backdrop */}
          <Transition.Child
            className="fixed inset-0 z-10 bg-black bg-opacity-60 transition-opacity"
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            aria-hidden="true"
          />
          {/* End: Modal backdrop */}

          {/* Modal dialog */}
          <Transition.Child
            className="fixed inset-0 z-10 flex p-6"
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <div className="max-w-5xl mx-auto h-full flex items-center">
              <Dialog.Panel className="w-full max-h-full rounded shadow-2xl aspect-video bg-black overflow-hidden">
                <iframe
                  className="vimeo-video"
                  src={vimeo}
                  title="Watch Video"
                  frameBorder="0"
                  allowFullScreen
                  style={{ width: videoWidth, maxWidth: '100%' }}
                ></iframe>
              </Dialog.Panel>
            </div>
          </Transition.Child>
          {/* End: Modal dialog */}
        </Dialog>
      </Transition>
    </>
  );
}
