import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/fetcher';
import Spinner from '../common/Spinner';
import { UserT } from '../../utils/types';
import { signOut } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import updateUserInExtension from '../../utils/updateUserInExtension';

type Props = {
  user: UserT | undefined;
  postLogout: () => void;
};

const REQUIRED_TEXT = 'REQUEST TO DELETE';
const GENERIC_ERR_MSG =
  "we couldn't process your request at the moment, please try again later";

export default function DeleteAccountRequest({ user, postLogout }: Props) {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    if (!user.isVerified) {
      navigate('/login/verify');
      return;
    }
  }, [user, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuccess(false);
    const value = e.target?.value;
    setInputText(value);
  };

  const resetError = () => {
    setError('');
  };

  const submitRequest = async (event: any) => {
    if (loading) {
      return;
    }
    event.preventDefault();
    if (!user || inputText !== REQUIRED_TEXT) {
      return;
    }
    try {
      setLoading(true);
      const response = await post({
        path: 'api/request_deletion',
      });
      if (response && response.status === 'success') {
        setSuccess(true);
      } else {
        setError(GENERIC_ERR_MSG);
      }
      setLoading(false);
    } catch (error) {
      setError(GENERIC_ERR_MSG);
      setLoading(false);
    }
  };

  const signout = async () => {
    signOut(auth);
    try {
      updateUserInExtension({}, 'user-logout');
    } catch (error: any) {
      // just log in console if couldn't communicate to extension
      // atleast logout from website
      console.log(error);
    }
    await post({
      path: 'api/logout',
    });
    postLogout();
  };

  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white px-5 py-3 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-3">
          <h2 className="text-2xl font-bold">Account Deletion Request</h2>
          <p className="text-sm mb-2 mt-3">
            {!success &&
              `Type "${REQUIRED_TEXT}" below to confirm account deletion. `}{' '}
            Our team will review your request and deletion will be completed
            within 5 working days.
          </p>
          <div>
            <form
              className={`${success ? '' : 'mt-6'}`}
              action="#"
              method="POST"
            >
              {error !== undefined && (
                <div className="text-sm text-red-500 mb-2">{error}</div>
              )}
              {!success && (
                <div>
                  <input
                    type="text"
                    placeholder={`Type ${REQUIRED_TEXT} here`}
                    onChange={handleInputChange}
                    onFocus={resetError}
                    autoFocus
                    required
                    className="w-full px-4 py-3 rounded bg-white border focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
              )}
              <button
                id="update-profile"
                type="submit"
                className={`w-full inline-block align-middle font-semibold rounded px-4 py-3 mt-6 ${
                  inputText !== REQUIRED_TEXT || success
                    ? 'bg-gray-500 text-gray-700/30'
                    : 'bg-purple-800 text-white hover:bg-button'
                }`}
                onClick={submitRequest}
                disabled={loading || success || inputText !== REQUIRED_TEXT}
              >
                {!user || loading ? (
                  <Spinner size={6} />
                ) : success ? (
                  'Request Successfully submitted ✅'
                ) : (
                  'SEND REQUEST'
                )}
              </button>
            </form>
            {success && (
              <div className="text-sm mt-2 text-center">
                <button className="underline" onClick={signout}>
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
