import { useEffect } from 'react';
import Spinner from '../common/Spinner';

type ExternalRedirectProps = {
  url: string;
};

const ExternalRedirect = ({ url }: ExternalRedirectProps) => {
  useEffect(() => {
    // Redirect to the external URL
    window.location.replace(url);
  }, [url]);
  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-5">
          <h2 className="text-2xl font-bold">Redirecting... please wait</h2>
          <p className="text py-2">
            We're taking you to the page. Thank you for your patience.
          </p>
          <div className="mt-6 text-center">
            <Spinner size={6} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExternalRedirect;
