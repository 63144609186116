import { UserT } from './types';

const extension_ids = [
  process.env.REACT_APP_EXTENSION_ID,
  process.env.REACT_APP_EXTENSION_LEGACY_ID,
  process.env.REACT_APP_EXTENSION_EDGE_ID,
];

const CommsMessageToExtension = 'VR_MESSAGE_TO_EXTENSION';

export default function updateUserInExtension(
  userData: UserT,
  type: 'user-login' | 'user-logout' = 'user-login'
) {
  const message = {
    type,
    data: {
      userData,
    },
  };
  if (!window.chrome?.runtime) {
    if (process.env.REACT_APP_ENABLE_FIREFOX === 'true') {
      document.dispatchEvent(
        new CustomEvent(CommsMessageToExtension, {
          detail: message,
        })
      );
    }
    return;
  }
  extension_ids.forEach((id) => {
    chrome.runtime.sendMessage(id, message, () => {
      if (chrome.runtime.lastError) {
        // Just swallow it at this point.
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        chrome.runtime.lastError;
      }
    });
  });
}
