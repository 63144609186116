import { useEffect } from 'react';
import Spinner from '../common/Spinner';
import { LogEventTrigger, LogEventType, sendLogs } from '../../utils/log';
import { v4 as randomUUID } from 'uuid';

export default function Uninstall() {
  useEffect(() => {
    // try logging in frontend
    sendLogs([
      {
        id: randomUUID(),
        event_type: LogEventType.UNINSTALL,
        trigger: LogEventTrigger.URL_OPENED,
        occurred_on: new Date(),
      },
    ])
      .then((output) => {})
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        // open the form
        window.location.replace('https://forms.gle/hey8LeVqqZ61VYxZ9');
      });
  }, []);

  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-5">
          <h2 className="text-2xl font-bold">Redirecting... please wait</h2>
          <p className="text py-2">
            We're taking you to the page. Thank you for your patience.
          </p>
          <div className="mt-6 text-center">
            <Spinner size={6} />
          </div>
        </div>
      </div>
    </div>
  );
}
