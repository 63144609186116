import { UserT } from '../../utils/types';
import ScrollToTop from '../common/ScrollToTop';
import Header from '../header/Header';

type Props = {
  children: React.ReactNode;
  user: UserT | undefined;
  hideProfile?: boolean;
  postLogout: () => void;
};

export default function Container({
  user,
  postLogout,
  children,
  hideProfile,
}: Props) {
  return (
    <>
      <Header user={user} postLogout={postLogout} hideProfile={hideProfile} />
      <div className={`flex w-full flex-col h-full text-text-heading`}>
        <div className="flex w-full h-full items-start flex-col">
          <ScrollToTop />
          {children}
        </div>
      </div>
    </>
  );
}
