import { useEffect } from 'react';
import Spinner from '../common/Spinner';
import { getParams, sanitizeUTMField } from '../../utils/browserSupport';
import { useNavigate } from 'react-router-dom';

export default function Start() {
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const source = sanitizeUTMField(queryParams.get('utm_source'));
    if (
      !localStorage.getItem('utm') ||
      (localStorage.getItem('utm_source') === 'homepage' &&
        source &&
        source !== 'homepage')
    ) {
      // write if utm details doesn't exist in local storage - we don't want to override with new values so that we can track the first source
      const source = sanitizeUTMField(queryParams.get('utm_source'));
      const medium = sanitizeUTMField(queryParams.get('utm_medium'));
      const campaign = sanitizeUTMField(queryParams.get('utm_campaign'));
      const utm_id = sanitizeUTMField(queryParams.get('utm_id'));
      const utm_term = sanitizeUTMField(queryParams.get('utm_term'));
      const utm_content = sanitizeUTMField(queryParams.get('utm_content'));
      if (source || medium || campaign) {
        source && localStorage.setItem('utm_source', source);
        medium && localStorage.setItem('utm_medium', medium);
        campaign && localStorage.setItem('utm_campaign', campaign);
        utm_id && localStorage.setItem('utm_id', utm_id);
        utm_term && localStorage.setItem('utm_term', utm_term);
        utm_content && localStorage.setItem('utm_content', utm_content);
        localStorage.setItem('utm', 'true');
      }
    }
    // redirect to the required url
    const redirectURL = 'https://sqrx.com' + getParams();
    // append fields
    if (redirectURL) {
      window.location.replace(redirectURL);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="mt-8 w-full h-full flex items-center justify-center">
      <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
        <div className="w-full p-5">
          <h2 className="text-2xl font-bold">Hi there!</h2>
          <p className="text py-2">
            Just a moment while we set the stage for an amazing journey
          </p>
          <div className="mt-6 text-center">
            <Spinner size={6} />
          </div>
        </div>
      </div>
    </div>
  );
}
