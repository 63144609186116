/**
 * Confirms if password policy is respected and returns an empty list,
 * if not respected returns list of validation error line items to show in UI
 * @param {string} password the password to check for
 */
export const checkPwdPolicy = (password: string, email: string) => {
  let failedList = ['Password policy failed: '];
  const transformedEmail = email.trim().toLowerCase();
  if (
    transformedEmail !== '' &&
    password.trim().toLowerCase().includes(transformedEmail)
  ) {
    failedList.push('• password cannot contain email');
    return failedList;
  }
  if (password.length < 8) {
    failedList.push('• minimum 8 characters should be present');
  }
  if (password.length > 1000) {
    failedList.push('• maximum 1000 letters are allowed');
  }
  if (!/[A-Z]+/.test(password)) {
    failedList.push('• atleast one upper case alphabet should be present');
  }
  if (!/[a-z]+/.test(password)) {
    failedList.push('• atleast one lower case alphabet should be present');
  }
  if (!/[0-9]+/.test(password)) {
    failedList.push('• atleast one number should be present');
  }
  if (!/[^\w\s]/.test(password)) {
    failedList.push('• atleast one special character should be present');
  }
  // if (/[^0-9A-Za-z^$*.[\]{}()?"!@#%&/,><':;|_~`]+/.test(password)) {
  //   failedList.push(
  //     '• invalid character used, allowed characters are alphabets, numbers and ^ $ * . [ ] { } ( ) ? " ! @ # % & /  , > < \' : ; | _ ~ `'
  //   );
  // } else if (!/[\^$*.[\]{}()?"!@#%&/,><':;|_~`]+/.test(password)) {
  //   // check for special chars only if valid character check passes
  //   failedList.push('• atleast one special characters should be present');
  // }
  if (failedList.length === 1) return [];
  return failedList;
};
