const MAX_TRIES = 1000;
const TIMEOUT = 1000;

export enum SocketCloseCode {
  MessageDone = 4000,
}

const force_disconnect_code_list = Object.values(SocketCloseCode).filter(
  (a) => !isNaN(Number(a))
);

const state = {
  retries: MAX_TRIES,
  reconnectTimeoutHandle: null as number | null,
  socket: null as WebSocket | null,
  session: '',
  passcode: '',
};

export function setupSocketInput(session: string, passcode: string) {
  state.session = session;
  state.passcode = passcode;
}

function sendMessage() {
  state.socket?.send(
    JSON.stringify({
      code: 'operation',
      operation: 'zip_password',
      body: {
        password: state.passcode,
      },
    })
  );
}

export function relayCode() {
  console.log('Trying To Connect', state.retries, window);
  if (state.socket && state.socket.readyState === state.socket.OPEN) {
    console.log('Instructed to connect the socket, but it is already connect');
    // send passcode
    sendMessage();
  }
  try {
    const url = `wss://${state.session}-sc/ws`;
    state.socket = new WebSocket(url);
    state.socket.onopen = (event: Event) => {
      state.retries = MAX_TRIES;
      console.log('Socket Connection Was Created', event);
      // as soon as connection is establish, send the passcode
      sendMessage();
    };
    state.socket.onmessage = (event: MessageEvent<string>) => {
      const { data } = event;
      const message = JSON.parse(data);
      console.log('Received from websocket', message);
      if (message.message === 'opening_file') {
        // success case
        document.dispatchEvent(
          new CustomEvent('zip_attempt', { detail: true })
        );
      } else if (message.message === 'incorrect_password') {
        // incorrect password
        document.dispatchEvent(
          new CustomEvent('zip_attempt', { detail: false })
        );
      }
    };
    state.socket.onclose = (event: CloseEvent) => {
      console.log('Socket Connection Closed', event);
      if (force_disconnect_code_list.includes(event.code)) {
        // If the client has requested a disconnect, then we wont auto reconnect
        return;
      }
      state.retries -= 1;
      if (state.retries >= 0) {
        state.reconnectTimeoutHandle = window.setTimeout(
          relayCode,
          TIMEOUT * (MAX_TRIES - state.retries)
        );
      } else {
        console.log(
          'Tried 5 times to reconnect to websocket, stopping retries now.'
        );
      }
    };
  } catch (error) {
    console.log('Error in Email connection', error);
  }
}

export function disconnectSocket() {
  if (!state.socket || state.socket.readyState !== state.socket.OPEN) {
    return console.log(
      'Instructed to disconnect the socket but it has already gone',
      state.socket
    );
  }
  state.socket.close(SocketCloseCode.MessageDone, 'client_requested');
}
