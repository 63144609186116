// makes first letter capitalised in every word
export function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

/**
 * Masks a given email
 * @param {string} input the input email
 */
export function maskEmail(input: string) {
  return input.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
  );
}

/**
 * Shorthand for `document.getElementById`
 * @param {string} id The ID of the element to select
 * @returns The HTML element with the matching ID, or null
 */
export function $id<T extends HTMLElement = HTMLElement>(id: string) {
  return document.getElementById(id) as T;
}

/**
 * Shows the specified elements
 */
export function showElements(...el: Element[]) {
  el.forEach((e) => e && e.classList.remove('hidden'));
}

/**
 * Hides the specified elements
 */
export function hideElements(...el: Element[]) {
  el.forEach((e) => e && e.classList.add('hidden'));
}

/**
 * Validates a given name for character
 * @param {string} input the input name
 * @returns {string | undefined} reason in case validation fails
 */
export function validateName(input: string): string | undefined {
  if (!/^[a-zA-Z\s]+$/.test(input)) {
    return 'only alphabets and spaces are allowed in name';
  }
  if (input.length > 70) {
    return 'maximum 70 characters allowed in name';
  }
  return undefined;
}
