export enum PlatformType {
  Extension = 'extension',
  WebApp = 'webapp',
}

export type UserT = {
  name?: string;
  id?: string;
  email?: string;
  isVerified?: boolean;
  platform?: PlatformType;
  idp?: string;
} | null;

export type AuthorizeResponse = {
  is_activated: boolean;
  name: string;
  email: string;
  status: 'success' | 'failure';
  gender?: 'male' | 'female' | undefined;
};

export type UserAuthResponse = {
  name: string;
  email: string;
  country: string;
  user_id: string;
  is_activated: boolean;
  status: 'success' | 'failed';
  platform?: PlatformType;
  idp?: string;
};
