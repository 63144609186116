import { useState, useMemo, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Login from './components/login/Login';
import VerifyUser from './components/verifyUser/VerifyUser';
import Welcome from './components/welcome/Welcome';
import NotFound from './components/others/NotFound';
import WhereAmI from './components/whereami/WhereAmI';
import ExternalRedirect from './components/others/ExternalRedirect';
import PostInstall from './components/postInstall/PostInstall';

import './index.css';
import Start from './components/start/Start';
import QuotaExhausted from './components/quotaExhausted/QuotaExhausted';
import Launch from './components/launch/Launch';
import Uninstall from './components/uninstall/Uninstall';
import Share from './components/share/Share';
import { UserAuthResponse, UserT } from './utils/types';
import updateUserInExtension from './utils/updateUserInExtension';
import { authorizeUser } from './utils/useAuthorize';
import Container from './components/container/Container';
import FAQ from './components/faq/FAQ';
import Profile from './components/profile/Profile';
import AuthAction from './components/authaction/AuthAction';
import DeleteAccountRequest from './components/deleteAccountRequest/DeleteAccountRequest';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppWrapper = () => {
  const [user, setUser] = useState<UserT | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const processResponse = useCallback(
    (response: UserAuthResponse | undefined) => {
      if (!response || response.status !== 'success') {
        updateUserInExtension({}, 'user-logout');
        setUser(undefined);
        return;
      }
      const { is_activated, name, email, platform, idp } = response;
      const newUser: UserT = {
        isVerified: is_activated,
        name,
        email: email,
        platform,
        idp,
      };
      setUser(newUser);
      if (is_activated) {
        updateUserInExtension(newUser, 'user-login');
        return;
      }
    },
    []
  );

  const markActivated = useCallback(() => {
    // this function marks is activated as true for the user
    if (user) {
      setUser((prevState) => ({
        ...prevState,
        isVerified: true,
      }));
      updateUserInExtension({
        ...user,
        isVerified: true,
      });
    }
  }, [user]);

  const updateName = (name: string) => {
    setUser((prevState) => ({
      ...prevState,
      name: name,
    }));
  };

  useEffect(() => {
    authorizeUser()
      .then((response) => {
        processResponse(response);
      })
      .catch((err) => {
        console.log(err);
        processResponse(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [processResponse]);

  const router = useMemo(() => {
    return createBrowserRouter([
      {
        path: '/',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <Login user={user} processResponse={processResponse} />
          </Container>
        ),
      },
      {
        path: '/login',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <Login user={user} processResponse={processResponse} />
          </Container>
        ),
      },
      {
        path: '/login/verify',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <VerifyUser
              user={user}
              markActivated={markActivated}
              postLogout={() => {
                processResponse(undefined);
              }}
            />
          </Container>
        ),
      },
      {
        path: '/welcome',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
            hideProfile={true}
          >
            <Welcome
              user={user}
              postLogout={() => {
                processResponse(undefined);
              }}
            />
          </Container>
        ),
      },
      {
        path: '/start',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <Start />
          </Container>
        ),
      },
      {
        path: '/launch',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <Launch />
          </Container>
        ),
      },
      {
        path: '/report-bug',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <ExternalRedirect url="https://forms.gle/rTim5cjpCXWcvF1K9" />
          </Container>
        ),
      },
      {
        path: '/invite',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <ExternalRedirect url="https://forms.gle/3zzsCUGifgXyjefR7" />
          </Container>
        ),
      },
      {
        path: '/get-started',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
            hideProfile={true}
          >
            <PostInstall />
          </Container>
        ),
      },
      {
        path: '/quota-exhausted',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <QuotaExhausted heading="Daily Data Limit" />
          </Container>
        ),
      },
      {
        path: '/session-limit-exhausted',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <QuotaExhausted heading="Daily Session Limit" />
          </Container>
        ),
      },
      {
        path: '/where-am-i',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <WhereAmI />
          </Container>
        ),
      },
      {
        path: '/share',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <Share />
          </Container>
        ),
      },
      {
        path: '/uninstall',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <Uninstall />
          </Container>
        ),
      },
      {
        path: '/faq',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <FAQ user={user} />
          </Container>
        ),
      },
      {
        path: '/profile',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <Profile user={user} updateName={updateName} />
          </Container>
        ),
      },
      {
        path: '/auth/action',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <AuthAction />
          </Container>
        ),
      },
      {
        path: '/delete-account-request',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <DeleteAccountRequest
              user={user}
              postLogout={() => {
                processResponse(undefined);
              }}
            />
          </Container>
        ),
      },
      {
        path: '*',
        element: (
          <Container
            user={user}
            postLogout={() => {
              processResponse(undefined);
            }}
          >
            <NotFound />
          </Container>
        ),
      },
    ]);
  }, [user, markActivated, processResponse]);

  return <>{loading ? '' : <RouterProvider router={router} />}</>;
};

root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
