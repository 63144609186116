import { post } from './fetcher';

export enum LogEventType {
  UNINSTALL = 172,
}

export enum LogEventTrigger {
  URL_OPENED = 'url_opened',
}

export interface LogEvent {
  id: string;
  event_type: LogEventType;
  trigger: string;
  event_code?: string;
  event_details?: unknown;
  occurred_on: Date;
}

export const sendLogs = async (logs: LogEvent[]) => {
  return post({
    path: 'api/log',
    payload: {
      logs,
    },
  });
};
