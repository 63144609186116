import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import updateUserInExtension from '../../utils/updateUserInExtension';
import { PWA_URL, WEB_APP_URL } from '../../utils/useAuthorize';
import { auth } from '../../utils/firebase';
import {
  getBrowserField,
  getCleanBrowserName,
  isBrowserSupported,
} from '../../utils/browserSupport';
import contactExtension from '../../utils/extensionInstalled';
import { toTitleCase } from '../../utils/common';
import Spinner from '../common/Spinner';
import getStoreLink from '../../utils/browserSupport';
import { UserT } from '../../utils/types';
import VideoModalButton from '../common/VideoModalButton';
import { signOut } from 'firebase/auth';
import { post } from '../../utils/fetcher';

type Props = {
  user: UserT | undefined;
  postLogout: () => void;
};

export default function Welcome({ user, postLogout }: Props) {
  const [detectionDone, setDetectionDone] = useState<boolean>(false); // page loads once this and user data is set
  const [extensionInstalled, setExtensionInstalled] = useState<boolean>(false);
  const [isUnsupported, setIsUnsupported] = useState<boolean>(false);
  const [currentBrowser] = useState<string>(getCleanBrowserName());
  const navigate = useNavigate();

  useEffect(() => {
    if (!isBrowserSupported(currentBrowser)) {
      setIsUnsupported(true);
    }
    // check if extension installed
    contactExtension()
      .then((installed) => {
        if (installed) {
          setExtensionInstalled(true);
        }
        setDetectionDone(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [currentBrowser]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      updateUserInExtension({}, 'user-logout');
      return;
    }
    if (user?.isVerified === false) {
      navigate('/login/verify');
      updateUserInExtension({}, 'user-logout');
      return;
    }
  }, [user, navigate]);

  const openWebApp = () => {
    window.location.href = WEB_APP_URL;
  };

  const openPWA = () => {
    window.location.href = PWA_URL;
  };

  const openChromeStore = () => {
    window.location.href = getStoreLink(false);
  };

  const signout = async () => {
    signOut(auth);
    try {
      updateUserInExtension({}, 'user-logout');
    } catch (error: any) {
      // just log in console if couldn't communicate to extension
      // atleast logout from website
      console.log(error);
    }
    await post({
      path: 'api/logout',
    });
    postLogout();
  };

  return (
    <>
      {user?.name && detectionDone ? (
        <div className="w-full h-full pt-44 flex justify-center">
          <div className="w-full px-6 vp:px-0 max-w-[1440px]">
            {/* main content */}
            {/* left side content */}
            <div className="w-full lg:max-w-[500px] text-center lg:text-left">
              <h2 className="text-2xl">
                Hi,{' '}
                <span className="data-hj-suppress">
                  {toTitleCase(user?.name)}
                </span>{' '}
                👋
              </h2>
              <p className="pt-4">
                You're all set to experience browsing like never before ✨
              </p>
              <p className="pt-4">
                SquareX secures your online activities without compromising
                productivity.
                {!extensionInstalled
                  ? ' Install the extension to unlock full power of SquareX.'
                  : ''}
              </p>
              <div className="pt-4">
                {!extensionInstalled ? (
                  <>
                    <span className="group relative">
                      <button
                        id="welcome-add-browser-btn"
                        className="text-lg inline-block align-middle text-white rounded px-4 py-3 mt-6 bg-purple-800 hover:bg-button mr-4 disabled:opacity-50"
                        disabled={isUnsupported}
                        onClick={openChromeStore}
                      >
                        {isUnsupported
                          ? `Unavailable for ${currentBrowser}`
                          : 'Add to your browser'}
                      </button>
                    </span>
                    <button
                      id="welcome-webapp-btn"
                      className="text-lg inline-block align-middle rounded px-4 py-3 mt-6 text-gray-700 bg-white hover:bg-gray-300"
                      onClick={openWebApp}
                    >
                      Use Web App
                    </button>
                    {isUnsupported ? (
                      <p className="text-sm pt-2">
                        {process.env.REACT_APP_ENABLE_FIREFOX !== 'true'
                          ? '(Extension available only on Google Chrome, Microsoft Edge and Brave Browser)'
                          : '(Extension available only on Google Chrome, Microsoft Edge, Firefox and Brave Browser)'}
                      </p>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="flex flex-col lg:items-start gap-4 pb-20 mt-6 items-center">
                {extensionInstalled && (
                  <button onClick={openWebApp}>
                    🚀 <span className="underline">Use Web App</span>
                  </button>
                )}
                {process.env.REACT_APP_ENABLE_PWA === 'true' &&
                  extensionInstalled &&
                  currentBrowser === 'Chrome' && (
                    <button onClick={openPWA}>
                      📁{' '}
                      <span className="underline">
                        Try SquareX Local File Viewer
                      </span>
                    </button>
                  )}
                <VideoModalButton
                  vimeo="https://player.vimeo.com/video/877790622?autoplay=1"
                  videoWidth={768}
                >
                  🎬{' '}
                  <span className="underline">
                    Watch Video: Getting Started with SquareX
                  </span>
                </VideoModalButton>
                <button
                  onClick={() => {
                    navigate('/faq');
                  }}
                >
                  🙋{' '}
                  <span className="underline">Frequently Asked Questions</span>
                </button>
                <button
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  🛠️ <span className="underline">Update Profile</span>
                </button>
                <button onClick={signout}>
                  🔒 <span className="underline">Sign Out</span>
                </button>
              </div>
            </div>
            {/* end of left side content */}
            {/* right side content for big screens */}
            {extensionInstalled ? (
              <>
                <div
                  className={`fixed hidden lg:inline-block top-0 ${getBrowserField(
                    currentBrowser,
                    'start-right'
                  )}`}
                >
                  <img
                    src="/arrow.gif"
                    className="max-h-[120px]"
                    alt="arrow up"
                  />
                </div>

                <div className="fixed top-[6rem] right-[2.25rem] border bg-white flex rounded shadow-lg p-5 hidden lg:inline-block">
                  <div className="w-full p-3">
                    <h6 className="text-xl font-bold pb-5 flex align-middle">
                      Open SquareX
                    </h6>
                    <p>
                      <img
                        src={getBrowserField(currentBrowser, 'start-gif')}
                        alt="Open SquareX"
                        className="max-w-[550px]"
                      />
                    </p>
                    <div className="flex max-h-[50px]">
                      <div className="align-middle leading-[5.5rem] ">
                        <button
                          onClick={() => navigate('/get-started')}
                          className="underline text-sm"
                        >
                          I don't see the SquareX icon
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={`fixed right-[2.25rem] top-24`}>
                <img
                  className="border-2 border-darkText max-w-[650px] hidden lg:inline-block ml-20"
                  src={getBrowserField(currentBrowser, 'welcome')}
                  alt="SquareX Extension"
                />
              </div>
            )}
            {/* end of right side content for big screens */}
            {/* end of main content */}
          </div>
        </div>
      ) : (
        <div className="mt-8 w-full h-full flex items-center justify-center">
          <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
            <div className="w-full p-5">
              <h2 className="text-2xl font-bold">Getting you ready</h2>
              <p className="text py-2">
                Please wait while we prepare your access
              </p>
              <div className="mt-6 text-center">
                <Spinner size={6} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
