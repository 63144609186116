import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { WEB_APP_URL, authorizeUser } from '../../utils/useAuthorize';
import { SsoT } from './types';
import SsoLoginButton from './SsoButton';
import Spinner from '../common/Spinner';
import EmailSigninForm from './EmailSigninForm';
import { getRedirectResult } from 'firebase/auth';
import { getCleanBrowserName, isIOS } from '../../utils/browserSupport';
import {
  auth,
  checkConflictedProviders,
  loginWithFB,
  loginWithGoogle,
  loginWithMicrosoft,
  loginWithTwitter,
} from '../../utils/firebase';
import { PlatformType, UserAuthResponse, UserT } from '../../utils/types';
import updateUserInExtension from '../../utils/updateUserInExtension';

// defines the login options to enable
const ENABLED_SSO: (SsoT | 'email')[] = [
  'email',
  'google',
  'twitter',
  'microsoft',
];

const GENERIC_ERR_MSG = 'something went wrong, please try again later';

type Props = {
  user: UserT | undefined;
  processResponse: (response: UserAuthResponse | undefined) => void;
};

export default function Login({ user, processResponse }: Props) {
  const [showSSO, setShowSSO] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[] | []>(); // error messages to show in login page
  const [activeSSO, setActiveSSO] = useState<SsoT | undefined>(); // keeps track of attempted sso mode
  const [hasSsoAuth, setHasSsoAuth] = useState<boolean | null>(null); // true indicates SSO redirect has just happened
  const [isNewAccount, setNewAccount] = useState<boolean>(true); // true indicates form mode is for sign up
  const [emailAttempted, setEmailAttemptedValue] = useState<boolean>(false); // true indicates form mode is for email attempted
  const [customTitle, setCustomTitle] = useState<string>(''); // sets the custom title
  const navigate = useNavigate();

  // set to sign up mode of the form
  const setModeToSignUp = (isSignUp: boolean) => {
    setNewAccount(isSignUp);
  };

  // set error message
  const setErrorMessages = (messages: string[]) => {
    setErrors(messages);
  };

  // set email attempted value
  const setEmailAttempted = (value: boolean) => {
    setEmailAttemptedValue(value);
  };

  // set custom form title
  const setCustomFormTitle = (title: string) => {
    setCustomTitle(title);
  };

  const authorizeAndSetUser = useCallback(
    async (idToken: string | void | undefined) => {
      if (typeof idToken !== 'string') {
        return;
      }
      try {
        const response = await authorizeUser(idToken);
        processResponse(response);
      } catch (error) {
        setErrors([GENERIC_ERR_MSG]);
      }
    },
    [processResponse]
  );

  useEffect(() => {
    getRedirectResult(auth)
      .then(async (result) => {
        setHasSsoAuth(!!result);
        try {
          if (!result) {
            return;
          }
          const token = await result.user.getIdToken();
          await authorizeAndSetUser(token);
        } catch (ex) {
          setErrors([GENERIC_ERR_MSG]);
        }
      })
      .catch((error) => {
        // handle sso error cases
        if (error.code === 'auth/account-exists-with-different-credential') {
          checkConflictedProviders(error.email, '')
            .then((providerInfo) => {
              if (providerInfo.provider === '') {
                // no existing provider, should not happen ideally
                setErrors([GENERIC_ERR_MSG]);
              } else {
                // show message to login using existing provider
                let existingProvider = providerInfo.provider;
                if (existingProvider === 'password') {
                  // transform provider for human friendly message
                  existingProvider = 'email and password';
                }
                setErrors([
                  'account exists with another login method, please login using ' +
                    existingProvider,
                ]);
              }
            })
            .catch((err) => setErrors([GENERIC_ERR_MSG]));
        } else {
          setErrors([GENERIC_ERR_MSG]);
        }
        setHasSsoAuth(false);
      });
  }, [authorizeAndSetUser]);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENABLE_SAFARI_IOS_SSO !== 'true' &&
      (isIOS() || getCleanBrowserName() === 'Safari')
    ) {
      // hide SSO options in ios and safari until issue solved
      setShowSSO(false);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      updateUserInExtension({}, 'user-logout');
      return;
    } else if (user?.isVerified === false) {
      navigate('/login/verify');
      updateUserInExtension({}, 'user-logout');
      return;
    } else if (user.isVerified) {
      if (sessionStorage.getItem('currentPlatform') === PlatformType.WebApp) {
        return window.location.replace(WEB_APP_URL);
      }
      if (user.platform === PlatformType.WebApp) {
        return window.location.replace(WEB_APP_URL);
      }
      return navigate('/welcome');
    }
  }, [user, navigate]);

  // error messages for SSO errors
  const getErrorMessageFromSSOError = async (error: any) => {
    switch (error.code) {
      case 'auth/account-exists-with-different-credential':
        if (error.customData.email) {
          const providerInfo = await checkConflictedProviders(
            error.customData.email,
            ''
          );
          if (providerInfo.provider !== '') {
            // show message to login using existing provider
            let existingProvider = providerInfo.provider;
            if (existingProvider === 'password') {
              // transform provider for human friendly message
              existingProvider = 'email and password';
            }
            return (
              'account exists with another login method, please login using ' +
              existingProvider
            );
          }
        }
        break;
      case 'auth/popup-blocked':
        return 'please provide permission to open popups to use this login method';
      case 'auth/popup-closed-by-user':
        return 'popup closed before completing the login steps, please try again';
      default:
        console.log(error.code);
    }
    return GENERIC_ERR_MSG;
  };

  // handles click event on selecting SSO from one of the provider
  const handleSsoClick = (method: SsoT) => async () => {
    if (method === undefined) {
      return;
    }
    const methodFxnMap = {
      facebook: loginWithFB,
      google: loginWithGoogle,
      twitter: loginWithTwitter,
      microsoft: loginWithMicrosoft,
    };
    const fxn = methodFxnMap[method];
    if (!fxn) {
      throw new Error('Invalid login method passed.');
    }
    setActiveSSO(method);
    try {
      const idToken: string | void | undefined = await fxn();
      await authorizeAndSetUser(idToken);
    } catch (error: any) {
      let message = await getErrorMessageFromSSOError(error);
      setErrors([message]);
    }
    setActiveSSO(undefined);
  };

  return (
    <div className="mt-8 w-full flex pt-[100px] xl:pt-0 xl:h-full xl:items-center justify-center">
      {hasSsoAuth === null ? (
        <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
          <div className="w-full p-5">
            <h2 className="text-2xl font-bold">Getting you ready</h2>
            <p className="text py-2">
              Please wait while we prepare your access
            </p>
            <div className="mt-6 text-center">
              <Spinner size={6} />
            </div>
          </div>
        </div>
      ) : hasSsoAuth ? (
        <div className="bg-white p-5 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
          <div className="w-full p-5">
            <h2 className="text-2xl font-bold">Logging In</h2>
            <p className="text py-2">Sit tight while we securely log you in</p>
            <div className="mt-6 text-center">
              <Spinner size={6} />
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white px-5 py-3 flex rounded shadow-lg max-w-[460px] min-w-[320px] w-full items-center justify-center">
          <div className="w-full p-3">
            <h2 className="text-2xl font-bold">
              {customTitle
                ? customTitle
                : isNewAccount
                ? 'Create your account'
                : 'Login'}
            </h2>
            {errors !== undefined && errors.length > 0
              ? errors.map((error, index) => {
                  return (
                    <div className="text-sm text-red-500 pt-5" key={index}>
                      {error}
                    </div>
                  );
                })
              : ''}
            {!emailAttempted &&
              showSSO &&
              ENABLED_SSO.map((sso) => {
                if (sso === 'email') {
                  return '';
                }
                return (
                  <SsoLoginButton
                    method={sso}
                    activeSSO={activeSSO}
                    handleSsoClick={handleSsoClick}
                    isNewAccount={isNewAccount}
                    key={sso}
                  />
                );
              })}
            <div
              className={ENABLED_SSO.includes('email') ? 'visible' : 'hidden'}
            >
              {!emailAttempted && showSSO ? (
                <div className="mt-7 grid grid-cols-3 items-center">
                  <hr />
                  <p className="text-center text-sm">OR</p>
                  <hr className="border-gray-600" />
                </div>
              ) : (
                ''
              )}
              <EmailSigninForm
                setCustomTitle={setCustomFormTitle}
                setErrorMessages={setErrorMessages}
                activeSSO={activeSSO}
                isNewAccount={isNewAccount}
                setModeToSignUp={setModeToSignUp}
                emailAttempted={emailAttempted}
                setEmailAttempted={setEmailAttempted}
                authorizeAndRedirectUser={authorizeAndSetUser}
              />
            </div>
            {!isNewAccount && customTitle === '' ? (
              <div className="text-sm mt-2 pt-4">
                <a
                  href="https://sqrx.com/terms"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>{' '}
                |{' '}
                <a
                  href="https://sqrx.com/privacy"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </div>
  );
}
