import { useEffect, useState } from 'react';
import updateUserInExtension from '../../utils/updateUserInExtension';
import { post } from '../../utils/fetcher';
import { signOut } from 'firebase/auth';
import { auth } from '../../utils/firebase';
import { UserT } from '../../utils/types';
import { useNavigate } from 'react-router-dom';
import Icons from '../login/Icons';
import { maskEmail } from '../../utils/common';

type HeaderProps = {
  user: UserT | undefined;
  postLogout: () => void;
  hideProfile?: boolean;
};

export default function Header({ user, postLogout, hideProfile }: HeaderProps) {
  const menuItemClass =
    'block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center';

  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const dropdown = document.getElementById('user-dropdown');
      const profileIcon = document.getElementById('user-menu-button');
      if (
        dropdownOpened &&
        !dropdown?.contains(event.target) &&
        !profileIcon?.contains(event.target)
      ) {
        setDropdownOpened(false);
        dropdown?.classList.add('hidden');
      }
    };

    const handleScrollEvent = () => {
      let y = window.scrollY || window.pageYOffset;
      if (y > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleScrollEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleScrollEvent);
    };
  }, [dropdownOpened]);

  const toggleMenu = () => {
    const dropdown = document.getElementById('user-dropdown');
    if (!dropdownOpened) {
      setDropdownOpened(true);
      dropdown?.classList.remove('hidden');
    } else {
      setDropdownOpened(false);
      dropdown?.classList.add('hidden');
    }
  };

  const logout = async () => {
    signOut(auth);
    try {
      updateUserInExtension({}, 'user-logout');
    } catch (error: any) {
      // just log in console if couldn't communicate to extension
      // atleast logout from website
      console.log(error);
    }
    await post({
      path: 'api/logout',
    });
    postLogout();
  };

  const navigateTo = (path: string) => {
    navigate(path);
    if (dropdownOpened) {
      toggleMenu();
    }
  };

  return (
    <div
      id="top-header"
      className={`fixed z-10 w-full flex items-center justify-center border-solid border-gray-100 ${
        scrolled ? 'bg-white' : ''
      }`}
    >
      <div className="flex h-[71px] items-center justify-between py-3 w-full max-w-[1440px] px-6 vp:px-0">
        <button
          className="p-0 text-left text-xl font-bold text-[#555] dark:text-[#ddd]"
          onClick={() => navigateTo('/welcome')}
        >
          [Square]<sup> x</sup>
          <p className="mt-0.5 text-xs">Be Fearless Online</p>
        </button>
        {user?.name && !hideProfile ? (
          <div className="flex items-center gap-2">
            {/* profile menu */}
            <button
              className={`group relative flex cursor-pointer items-center rounded-full border p-[6px] hover:border-purple-500 hover:text-purple-500 ${
                dropdownOpened
                  ? 'border-purple-500 text-purple-500'
                  : 'border-[#666] text-[#666] dark:border-[rgb(221,221,221)] dark:text-[rgb(221,221,221)]'
              }`}
              id="user-menu-button"
              onClick={toggleMenu}
            >
              <Icons.Profile width="15" height="15" />
              <span className="pointer-events-none absolute right-0 top-9 min-w-[50px] rounded bg-[#000a] p-2 text-xs font-medium text-[#fff] opacity-0 shadow transition-opacity group-hover:opacity-100">
                Profile
              </span>
            </button>
            {/* dropdown menu */}
            <div
              className="absolute top-14 z-50 -ml-36 hidden list-none divide-y divide-gray-100 rounded-lg bg-white text-base shadow"
              id="user-dropdown"
            >
              <div className="px-4 py-3">
                <span className="block text-sm text-gray-900">
                  {user?.name}
                </span>
                <span className="block truncate text-sm opacity-50">
                  {maskEmail(user?.email ?? '')}
                </span>
              </div>
              <ul className="py-2">
                <li>
                  <button
                    className={menuItemClass}
                    onClick={() => navigateTo('/profile')}
                  >
                    <Icons.Profile
                      width="15"
                      height="15"
                      className="mr-2 -ml-[2px] inline-block"
                    />
                    Update Profile
                  </button>
                  <button
                    className={menuItemClass}
                    onClick={() => navigateTo('/faq')}
                  >
                    <Icons.Help
                      width="15"
                      height="15"
                      className="mr-2 -ml-[2px] inline-block"
                    />
                    FAQ
                  </button>
                  <a className={menuItemClass} href="mailto:support@sqrx.com">
                    <Icons.Mail
                      width="15"
                      height="15"
                      className="mr-2 inline-block"
                    />
                    Contact Support
                  </a>
                </li>
                <li>
                  <button className={menuItemClass} onClick={logout}>
                    <Icons.SignOut
                      width="15"
                      height="15"
                      className="mr-2 inline-block"
                    />
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
